<script setup lang="ts">
import { onMounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import {
	isPlatform,
	IonButton,
	IonContent,
	IonFooter,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonRefresher,
	IonRefresherContent,
	InfiniteScrollCustomEvent,
} from '@ionic/vue';
import {
	closeOutline,
	fastFoodOutline,
	linkOutline,
	ticketOutline,
	qrCodeOutline,
	walletOutline,
	shareSocialOutline,
} from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { Event } from '@/shared/interfaces/event';
import { FeedTypeEnum } from '@/enums/values.enum';
import { Post } from '@/shared/interfaces/post';
import { useAuthStore } from '@/stores/auth/authStore';
import FeedItem from '@/shared/components/feed/FeedItem.vue';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import NewsHeader from '@/modules/news/components/NewsHeader.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useEvents from '@/modules/events/composables/useEvents';
import useFavorite from '@/shared/composables/useFavorite';
import useFeed from '@/modules/dashboard/composables/useFeed';
import usePosts from '@/modules/news/composables/usePosts';
import usePreload from '@/shared/composables/usePreload';
import useRegistrationForm from '@/modules/auth/composables/useRegistrationForm';
import useRfid from '@/modules/rfid/composables/useRfid';
import useSelfOrderingGetSpotId from '@/modules/self-ordering/components/useSelfOrderingGetSpotId';
import useTenant from '@/shared/composables/useTenant';
import useToast from '@/shared/composables/useToast';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import WalletBox from '@/shared/components/WalletBox.vue';
import YourId from '@/modules/dashboard/components/YourId.vue';
import { useAssetsStore } from '@/stores/shared/assetsStore';
import { Wallet } from '@/shared/interfaces/balance';
import useNotifications from '@/shared/composables/useNotifications';

// Init
const { t } = useI18n();
const route = useRoute();
const assetStore = useAssetsStore();
const authStore = useAuthStore();
const { getRedirect, setRedirect } = useAuth();
const { presentToast } = useToast();
const { getAutoOpenSelfOrder } = useSelfOrderingGetSpotId();
const { autoOpenSelfOrder } = getAutoOpenSelfOrder();
const isYourIdModalOpen = ref(false);
const isMainAlertModalOpen = ref(false);
const { getUserProfile, shareGuestTopup } = useUserProfile();
const { isFetchedUserProfile, refetchUserProfile, userProfile } = getUserProfile();
const {
	feedList,
	feedListLength,
	fetchNextPage,
	hasNextPage,
	refetchFeedDashboard,
	removeFeedDashboardResultsFromCache,
} = useFeed(false);
const { toggleFavorite } = useFavorite();
const { getRfidTags } = useRfid();
const { rfidTags } = getRfidTags();
const { currentMainAlertPost, markMainAlertAsSeenInCache, postSetMainAlertAsSeen, setCurrentMainAlert } = usePosts();
const { preloadCache } = usePreload();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig, showLoggedData } = getTenantConfigAndStyles();
const { getRegistrationFormForAdditionalData } = useRegistrationForm();
const { registrationForm } = getRegistrationFormForAdditionalData();
const { getEventsCount } = useEvents();
const eventsTotal = ref();
const selectedWallet = ref<Wallet>();
const walletBox = ref();
const { requestPushNotificationsPermission } = useNotifications();

// Init
onMounted(async () => {
	if (await authStore.isAuthenticated()) {
		eventsTotal.value = await getEventsCount('', 'going');
		await requestPushNotificationsPermission();

		// Handle voucherID as query param
		const voucherID = route.query.voucherID;
		if (voucherID) {
			router.push({
				path: 'top-up/redeem',
				query: {
					voucherID,
				},
			});
		}
	}
});

// Handle main alert
watchEffect(async () => {
	if (!window.apiUrl.includes('localhost')) {
		await preloadCache();
	}
	if (tenantConfig.value && userProfile.value && feedList.value) {
		const mainAlertIdx = feedList.value.findIndex((feedItem) => feedItem?.type === FeedTypeEnum.main_alert);
		const userCanSeePopup: boolean = tenantConfig.value.enable_public_mode
			? true
			: userProfile.value?.profile_completed;

		// If public mode is enabled or user profile is completed and there is a main alert, then show it
		if (userCanSeePopup && mainAlertIdx !== -1) {
			const feedData = { ...(feedList.value[mainAlertIdx].data as any) };
			// Show main alert
			setCurrentMainAlert(feedData.post).then((post: Post | null) => {
				if (post) {
					showHideModal(true);
				}
			});
		}
	}
});

// Main Alert
const showHideModal = async (openState: boolean) => {
	if (!openState) {
		const mainAlertIdx = feedList.value!.findIndex((feedItem) => feedItem?.type === FeedTypeEnum.main_alert);

		if (mainAlertIdx !== -1) {
			const feedData = { ...(feedList.value![mainAlertIdx].data as any) };

			if (await authStore.isAuthenticated()) {
				// If user is authenticated we will alert the BE that is post has been closed
				postSetMainAlertAsSeen(feedData.post);
			} else if (tenantConfig && tenantConfig.value && tenantConfig.value.enable_public_mode) {
				// In public mode we will set this cache entry as seen when you're not authenticated
				markMainAlertAsSeenInCache(feedData.post);
			}
		}
	}
	isMainAlertModalOpen.value = openState;
};

const checkRedirect = () => {
	const redirect = getRedirect();
	if (redirect.callbackPage && redirect.id) {
		// reset redirection and redirect
		setRedirect({ callbackPage: '', id: '' });
		router.push({ path: `/${redirect.callbackPage}/${redirect.id}`, query: { continueLinking: 'true' } });
	}
};

watchEffect(async () => {
	await authStore.authenticateWithSavedToken();
	const isAuthenticated = authStore.isAuthenticated();
	if (isAuthenticated) {
		await refetchUserProfile();
		if (
			userProfile.value &&
			!userProfile.value?.profile_completed &&
			registrationForm.value &&
			registrationForm.value.data &&
			registrationForm.value.data.length > 0
		) {
			router.push('/profile/additionaldata');
		}
		checkRedirect();
	}
});

// goTo
const goTo = async (link: string) => {
	await router.push(link);
};

// Check auth
const checkAuth = async () => {
	if (!authStore.isAuthenticated()) {
		await presentToast('top', t('general.please_login_first'), 5000, 'danger');
		await router.push('/auth/signuphome');
		return false;
	} else {
		return true;
	}
};

// showHideYourId
const showHideYourId = async (openState: boolean) => {
	const authCheckPassed = await checkAuth();
	if (authCheckPassed) {
		isYourIdModalOpen.value = openState;
	}
};

// Refresh
const handleRefresh = async (event: any) => {
	removeFeedDashboardResultsFromCache();
	feedList.value = [];
	await refetchFeedDashboard();
	walletBox.value?.refetchWalletsData();
	event.target.complete();
};

const openWalletManage = async (selectedWallet: Wallet) => {
	const authCheckPassed = await checkAuth();
	if (authCheckPassed) {
		await router.push(`/wallet/manage/${selectedWallet.id}`);
	}
};

// openSelfOrder
const openSelfOrder = async () => {
	const authCheckPassed = await checkAuth();
	if (authCheckPassed) {
		if (autoOpenSelfOrder && autoOpenSelfOrder.value && autoOpenSelfOrder.value.auto_open_self_order) {
			await router.push(`/self-ordering/get-spot-id?spotid=${autoOpenSelfOrder.value.auto_open_self_order_qr}`);
		} else {
			await router.push('/self-ordering/get-spot-id');
		}
	}
};

// openPayconiqLink
const openPayconiqLink = () => {
	if (isPlatform('android')) {
		Browser.open({
			url: 'https://play.google.com/store/apps/details?id=mobi.inthepocket.bcmc.bancontact&hl=en&gl=US',
		});
	}

	if (isPlatform('ios')) {
		Browser.open({ url: 'https://apps.apple.com/be/app/payconiq-by-bancontact/id858371800' });
	}
};

// Infinite scroll
const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
	if (hasNextPage && hasNextPage.value) {
		fetchNextPage();
	}
	ev.target.complete();
};

const selectWallet = (wallet: Wallet) => {
	selectedWallet.value = wallet;
};
</script>

<template>
	<ion-content>
		<ModalOne :title="currentMainAlertPost?.title" :is-open="isMainAlertModalOpen">
			<template #content>
				<div class="font-sourcesanspro-regular text-sm text-center text-white/50 leading-[18px]">
					<div v-dompurify-html="currentMainAlertPost?.content"></div>
				</div>
			</template>

			<template #actions>
				<div class="flex items-center justify-center">
					<div class="flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white">
						<ion-icon
							class="text-[38px] text-black cursor-pointer"
							:icon="closeOutline"
							@click="showHideModal(false)"></ion-icon>
					</div>
				</div>
			</template>
		</ModalOne>

		<ion-refresher class="mt-8" slot="fixed" @ionRefresh="handleRefresh($event)">
			<ion-refresher-content></ion-refresher-content>
		</ion-refresher>

		<YourId :is-modal-open="isYourIdModalOpen" @on-close-modal="showHideYourId"></YourId>

		<div class="px-[34px] pt-14">
			<div class="flex justify-center mb-8">
				<img :src="assetStore.logoUrl" class="max-w-[70%]" />
			</div>

			<div v-if="showLoggedData()" class="flex flex-col mb-2">
				<div class="font-sourcesanspro-semibold text-xs2 text-white/60 leading-none">
					{{ t('dashboard.hello') }}
				</div>
				<div class="flex text-base2 items-baseline">
					<div class="mr-2">
						<span class="font-sourcesanspro-semibold text-m2">{{
							userProfile?.id ? userProfile.full_name : userProfile?.firstname
						}}</span>
					</div>
					<span
						v-if="userProfile && userProfile.id !== 0"
						class="font-sourcesanspro-regular underline text-xs2 cursor-pointer"
						@click="goTo('/profile')">
						{{ t('dashboard.edit') }}
					</span>
					<span
						v-if="userProfile && userProfile.id === 0"
						class="font-sourcesanspro-regular underline text-xs2 cursor-pointer"
						@click="goTo('/auth/signuphome')">
						{{ t('dashboard.login') }}
					</span>
				</div>
			</div>

			<div
				v-if="showLoggedData() && userProfile && isFetchedUserProfile && userProfile.id !== 0"
				class="flex flex-wrap justify-between items-center mb-2">
				<ion-button
					class="flex grow min-w-[142px] max-w-[142px] h-[35px] ion-no-margin mb-2 slim text-xs hover:bg-white hover:rounded-full"
					shape="round"
					@click="goTo('/rfid')">
					<ion-icon class="mr-1" :icon="linkOutline"></ion-icon>
					{{ rfidTags.length }}{{ t('rfid.x_rfid') }}
				</ion-button>

				<ion-button
					v-if="
						userProfile &&
						isFetchedUserProfile &&
						userProfile.id !== 0 &&
						eventsTotal &&
						eventsTotal.count &&
						tenantConfig &&
						!tenantConfig?.hide_tickets
					"
					class="flex grow min-w-[142px] max-w-[142px] h-[35px] ion-no-margin mb-2 slim text-xs hover:bg-white hover:rounded-full"
					shape="round"
					@click="goTo('/events/?eventType=going')">
					<ion-icon class="mr-1" :icon="ticketOutline"></ion-icon>
					{{ eventsTotal.count }} {{ t('ticket.tickets') }}
				</ion-button>
			</div>
		</div>

		<div v-if="showLoggedData()">
			<WalletBox
				ref="walletBox"
				v-if="tenantConfig"
				:user="`${userProfile?.firstname} ${userProfile?.lastname}`"
				@on-wallet-set="selectWallet"></WalletBox>
		</div>

		<div class="px-[34px]">
			<div
				v-if="
					showLoggedData() &&
					Capacitor.isNativePlatform() &&
					tenantConfig?.viva_wallet_payconiq_preferred_mode
				"
				class="flex flex-col items-center justify-center mb-2 p-4 rounded backdrop-blur-xl bg-white/10 text-center">
				<span class="font-sourcesanspro-semibold text-sm2">{{
					t('dashboard.payconiq.dont_have_the_app')
				}}</span>
				<span class="font-sourcesanspro-regular text-xs2">{{ t('dashboard.payconiq.install') }}</span>
				<ion-button class="flex text-sm2 h-[48px] payconiq-button" @click="openPayconiqLink()"
					>{{ t('dashboard.payconiq.button_text') }}
				</ion-button>
			</div>

			<div
				v-if="showLoggedData() && !tenantConfig?.hide_wallet"
				class="grid grid-flow-col gap-2 justify-stretch mb-4 auto-cols-fr">
				<div
					v-if="tenantConfig?.self_order_activated"
					class="backdrop-blur-xl bg-white/10 h-[80px] rounded cursor-pointer">
					<ion-button
						class="flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent"
						@click="openSelfOrder()">
						<div class="flex flex-col items-center justify-center">
							<ion-icon class="mt-1" :icon="fastFoodOutline"></ion-icon>
							<span class="font-sourcesanspro-semibold text-sm2 text-white">{{
								t('self_ordering.title')
							}}</span>
							<span class="font-sourcesanspro-regular text-xxs text-white">{{
								t('self_ordering.subtitle')
							}}</span>
						</div>
					</ion-button>
				</div>

				<div class="backdrop-blur-xl bg-white/10 h-[80px] rounded cursor-pointer">
					<ion-button
						class="flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent"
						@click="openWalletManage(selectedWallet)">
						<div class="flex flex-col items-center justify-center">
							<ion-icon class="mt-1" :icon="walletOutline"></ion-icon>
							<span class="font-sourcesanspro-semibold text-sm2 text-white">{{
								t('wallet.manage_wallet.title')
							}}</span>
							<span class="font-sourcesanspro-regular text-xxs text-white">{{
								t('wallet.manage_wallet.description')
							}}</span>
						</div>
					</ion-button>
				</div>

				<div class="backdrop-blur-xl bg-white/10 h-[80px] rounded cursor-pointer">
					<ion-button
						class="flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent"
						@click="showHideYourId(true)">
						<div class="flex flex-col items-center justify-center">
							<ion-icon class="mt-1" :icon="qrCodeOutline"></ion-icon>
							<span class="font-sourcesanspro-semibold text-sm2 text-white">{{
								t('your_id.title')
							}}</span>
							<span class="font-sourcesanspro-regular text-xxs text-white">{{
								t('your_id.description')
							}}</span>
						</div>
					</ion-button>
				</div>
				<div
					v-if="tenantConfig && tenantConfig.guest_wallet_topup_clientx"
					class="backdrop-blur-xl bg-white/10 h-[80px] rounded cursor-pointer">
					<ion-button
						class="flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent"
						@click="shareGuestTopup()">
						<div class="flex flex-col items-center justify-center">
							<ion-icon class="mt-1" :icon="shareSocialOutline"></ion-icon>
							<span class="font-sourcesanspro-semibold text-sm2 text-white">{{
								t('guest_top_up.share.title')
							}}</span>
							<span class="font-sourcesanspro-regular text-xxs text-white">{{
								t('guest_top_up.share.subtitle')
							}}</span>
						</div>
					</ion-button>
				</div>
			</div>

			<div id="news" class="mb-2">
				<NewsHeader v-if="feedListLength" class="mb-2" />
				<FeedItem
					v-for="(item, idx) in feedList?.filter(
						(feed) =>
							feed.type === 'event_now' ||
							feed.type === 'event_soon' ||
							feed.type === 'event' ||
							feed.type === 'post',
					)"
					@on-favorite-clicked="toggleFavorite"
					:key="idx"
					:id="item.data.id"
					:canceled-at="
						item.type === 'event' || item.type === 'event_now' ? (item.data as Event).canceled_at : null
					"
					:favorite="item.data.is_favorite"
					:summary="item.data.summary ? item.data.summary : ''"
					:image="item.data.main_image_url ? item.data.main_image_url : ''"
					:link="`${item.type.includes('event') ? 'events' : 'news'}/${item.data.id}`"
					:type="FeedTypeEnum[item.type]"
					:location-address="item.type.includes('event') ? (item.data as Event).address : ''"
					:happening-now="item.type === 'event_now'"
					:happening-soon="item.type === 'event_soon'"
					:is-event="item.type === 'event' || item.type === 'event_now' || item.type === 'event_soon'"
					:date="item.type.includes('event') ? (item.data as Event).start_datetime : ''"
					:show-logged-data="showLoggedData()"
					:title="
						item.type === 'event_now' || item.type === 'event_soon' || item.type === 'event'
							? (item.data as Event).name
							: (item.data as Post).title
					" />
			</div>

			<ion-infinite-scroll @ionInfinite="ionInfinite">
				<ion-infinite-scroll-content></ion-infinite-scroll-content>
			</ion-infinite-scroll>

			<div class="h-[100px]">&nbsp;</div>
		</div>
	</ion-content>

	<ion-footer>
		<NavigationBar item-active="dashboard" />
	</ion-footer>
</template>

<style lang="scss" scoped>
.profile-button {
	--border-color: #b0b0b0;
}
</style>
