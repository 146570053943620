import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import vue, { ref } from 'vue';
import router from '@/router';
import * as yup from 'yup';
import { useForm } from 'vee-validate';
import { callOutline, mailOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useToast from '@/shared/composables/useToast';
import useLoading from '@/shared/composables/useLoading';
import { useReCaptcha } from 'vue-recaptcha-v3';
import { useSharedStore } from '@/stores/shared/sharedStore';
import useAlert from '@/shared/composables/useAlert';


export default /*@__PURE__*/_defineComponent({
  __name: 'SignUpWithPhoneForm',
  emits: ['onFormValidation'],
  setup(__props, { emit: __emit }) {

const { t } = useI18n();
const emit = __emit;
const { checkIfEmailExists, checkIfPhoneExists, registerUserWithPhone } = useAuth();
const { presentToast } = useToast();
const { presentLoading } = useLoading();
const { presentAlert } = useAlert();
const alertShown = ref(false);
const recaptcha = useReCaptcha();
const executeRecaptcha = recaptcha?.executeRecaptcha;
const sharedStore = useSharedStore();
const editingField = ref('');
const emailValid = ref(false);
const phoneValid = ref(false);
const phoneRegExp = /^\+[1-9]\d{7,14}$/;

const validationSchema = yup.object({
	email: yup
		.string()
		.required()
		.label(t('auth.sign_in_email_page.email_label'))
		.test('check-email', t('auth.sign_in_email_page.errors.email'), async (value) => {
			if (editingField.value === 'email') {
				const regex = new RegExp(sharedStore.EMAIL_REGX);
				const isEmailStringValid = regex.test(value);
				let isEmailValid = false;
				value = encodeURIComponent(value);
				try {
					isEmailValid = isEmailStringValid ? await checkIfEmailValid(value, isEmailStringValid) : false;
				} catch (e) {
					isEmailValid = false;
				}
				emailValid.value = isEmailStringValid && isEmailValid;
				emit('onFormValidation', phoneValid.value && emailValid.value);
				return emailValid.value;
			} else {
				return emailValid.value;
			}
		}),
	phone_number: yup
		.string()
		.required(t('auth.sign_up_phone_page.required'))
		.test('check-phone-number', t('auth.sign_up_phone_page.errors.phone_number'), async (value) => {
			if (editingField.value === 'phone_number') {
				const regex = new RegExp(phoneRegExp);
				const isPhoneStringValid = regex.test(value);
				const phoneNumber = value.replace('+', '%2B');
				let isPhoneValid = false;
				try {
					isPhoneValid = isPhoneStringValid ? await checkIfPhoneValid(phoneNumber) : false;
				} catch (e) {
					isPhoneValid = false;
				}
				phoneValid.value = isPhoneStringValid && isPhoneValid;
				emit('onFormValidation', phoneValid.value && emailValid.value);
				return phoneValid.value;
			} else {
				return phoneValid.value;
			}
		}),
});

const { handleSubmit, meta } = useForm({
	validationSchema: validationSchema,
});

const onSubmit = handleSubmit(async (formData: any) => {
	const loading = await presentLoading(t('general.please_wait'));

	try {
		let recaptchaToken = '';

		if (executeRecaptcha) {
			recaptchaToken = await executeRecaptcha('login_action');
		}

		await registerUserWithPhone({
			userData: {
				...formData,
			},
			recaptchaToken,
		});

		await loading.dismiss();
	} catch (error: any) {
		await loading.dismiss();

		await presentToast(
			'top',
			error.response?.data?.message ? error.response.data.message.toString() : error.toString(),
			5000,
			'danger',
		);
	}
});

const checkIfEmailValid = async (email: string, emailStringValid: boolean) => {
	const isValidEmail = await checkIfEmailExists(email);
	if (isValidEmail.exists) {
		if (!alertShown.value) {
			alertShown.value = true;
			await presentAlert({
				header: t('auth.sign_up.account_exists_already'),
				message: t('auth.sign_up.account_exists_already_description', {
					email_addresss: email,
				}),
				buttons: [
					{
						text: t('auth.sign_up.login'),
						role: 'ok',
						cssClass: 'secondary',
						handler: () => {
							alertShown.value = false;
							router.push('/auth/signinwithemail');
						},
					},
					{
						text: t('auth.sign_up.cancel'),
						role: 'cancel',
						cssClass: 'secondary',
						handler: () => {
							alertShown.value = false;
						},
					},
					{
						text: t('auth.sign_up.request_password'),
						role: 'ok',
						handler: () => {
							alertShown.value = false;
							router.push(`/profile/password/reset/request?email=${email}`);
						},
					},
				],
			});
		}
		return false;
	} else {
		return true;
	}
};

const checkIfPhoneValid = async (phone: string) => {
	const isPhoneValid = await checkIfPhoneExists(phone);
	if (isPhoneValid.exists) {
		return false;
	} else {
		return true;
	}
};

const onInputChange = async (formValid: vue.ComputedRef<boolean>, fieldName: any) => {
	editingField.value = fieldName.value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("form", {
    id: "signUpWithPhoneForm",
    onSubmit: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_unref(onSubmit) && _unref(onSubmit)(...args)))
  }, [
    _createVNode(TextInput, {
      "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
      iconLeft: _unref(callOutline),
      name: "phone_number",
      type: "tel",
      label: _ctx.$t('auth.sign_up_phone_page.phone_label'),
      placeholder: _ctx.$t('auth.sign_up_phone_page.phone'),
      "should-handle-blur": false,
      onInputChanged: onInputChange
    }, null, 8, ["iconLeft", "label", "placeholder"]),
    _createVNode(TextInput, {
      "wrapper-class": "flex flex-wrap justify-left item-center mb-2 border border-solid focus-within:background-white-15",
      iconLeft: _unref(mailOutline),
      name: "email",
      type: "email",
      label: _ctx.$t('auth.sign_up_phone_page.email_label'),
      placeholder: _ctx.$t('auth.sign_in_email_page.email'),
      "should-handle-blur": false,
      onInputChanged: onInputChange
    }, null, 8, ["iconLeft", "label", "placeholder"])
  ], 32))
}
}

})