<script setup lang="ts">
import { watch } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonInput, IonSpinner } from '@ionic/vue';
import { arrowForwardOutline, chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { TransactionType } from '@/enums/wallet.enum';
import { useTransactionStore } from '@/stores/transaction/transactionStore';
import hexRgb from 'hex-rgb';
import rgbHex from 'rgb-hex';
import ScanQR from '@/shared/components/ScanQR.vue';
import TopBar from '@/shared/components/TopBar.vue';
import type { FreeTickets } from '@/shared/interfaces/voucher';
import type { Transaction } from '@/shared/interfaces/transaction';
import useToast from '@/shared/composables/useToast';
import useUtils from '@/shared/composables/useUtils';
import useVoucher from '@/modules/vouchers/composables/useVoucher';

// Init
const { t } = useI18n();
const transactionStore = useTransactionStore();
const { setItemsAndTotal } = transactionStore;
const { errorVoucher, redeemVoucher, voucherText, voucherData, isLoadingVoucher } = useVoucher();
const { presentToast } = useToast();
const { calculateShade } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));

// Watch any change on voucherData
watch(voucherData, async () => {
	if (voucherData.value && voucherData.value.error) {
		// Show toast with error
		await presentToast('top', t(voucherData.value.error), 5000, 'danger');
	} else if (
		voucherData.value &&
		voucherData.value.voucherMetaData.status === 'redeemed' &&
		voucherData.value.voucherRedeemedItems.length
	) {
		const walletBalanceAdded: FreeTickets[] = voucherData.value.voucherRedeemedItems.filter(
			(item: FreeTickets) => item.item_type === 'wallet_balance',
		);

		const voucherItems: any = [];
		// Transform in one dimesion array
		voucherData.value.voucherRedeemedItems.forEach((item: any) => {
			if (item.length) {
				voucherItems.push(...item);
			} else {
				voucherItems.push(item);
			}
		});

		const transaction: Transaction = {
			items: voucherItems.map((item: any) => {
				let description = '';
				let quantity = null;
				let price = '';
				let currency = null;

				if (item.item_type === 'wallet_balance') {
					description = t('top_up.redeem_voucher.wallet_balance');
					currency = item.currency.prefix;
					price = item.amount;
				} else if (item.item_type === 'free_tickets') {
					description = `${item.item_extra_data!.event_name} - ${item.item_extra_data!.ticket_type_name}`;
					quantity = item.item_quantity;
					price = '0';
				} else if (item.item_type === 'tags_assignment') {
					description = `${t('top_up.redeem_voucher.now_identified_as')} ${item.item_names.join(' - ')}`;
				}

				return {
					description,
					quantity: quantity,
					itemPrice: price,
					currency,
					unitPrice: 0,
				};
			}),
			type: TransactionType.VOUCHER_TOP_UP,
			totalDescription: t('top_up.redeem_voucher.voucher_redeemed_items'),
			totalPriceString: walletBalanceAdded.length
				? `€${walletBalanceAdded[0].item_extra_data!.amount.toString()}`
				: '',
			totalPriceNumber: walletBalanceAdded.length ? walletBalanceAdded[0].item_extra_data!.amount : 0,
			totalPriceWithoutFee: 0,
		};
		setItemsAndTotal(transaction);
		await router.push({ path: '/transaction' });
	}
});

watch(errorVoucher, async () => {
	console.log(errorVoucher.value);
});

// onScanQR
const onScanQR = (qrCode: string) => {
	voucherText.value = qrCode;
};

// Go back
const goBack = () => {
	router.push({
		path: '/dashboard'
	});
};
</script>

<template>
	<ion-content :style="`background: linear-gradient(${primary_color}, ${primaryShade});`">
		<div class="flex flex-col h-full">
			<TopBar class="px-[34px]">
				<template #left>
					<div class="flex items-center justify-start cursor-pointer" @click="goBack">
						<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
					</div>
				</template>

				<template #center>
					<div class="flex grow justify-center w-full font-sourcesanspro-semibold text-l2">
						{{ t('top_up.redeem_voucher.title') }}
					</div>
				</template>
			</TopBar>

			<div v-if="isLoadingVoucher" class="flex grow flex-col items-center justify-center px-[34px]">
				<ion-spinner name="dots"></ion-spinner>
			</div>

			<div v-if="!isLoadingVoucher" class="flex grow flex-col justify-center px-[34px]">
				<div class="mb-4 font-sourcesanspro-regular text-center text-sm2 font-color-text-50">
					{{ t('top_up.redeem_voucher.redeem_here') }}
				</div>
				<div class="flex flex-wrap w-full justify-left item-center p-2 rounded-full background-white-15 mb-8">
					<div class="flex w-full items-center px-6">
						<ion-input
							v-model="voucherText"
							class="text-center font-sourcesanspro-regular text-l2 tracking-[.20em]"
							name="voucher-code"
							type="text"
							placeholder="xxxx - xxxx - xxxx">
						</ion-input>
					</div>
				</div>
				<div class="separator mb-8">
					<h5>
						<span class="font-sourcesanspro-regular text-base text-white/50">
							{{ t('top_up.redeem_voucher.redeem_or') }}
						</span>
					</h5>
				</div>
				<ScanQR
					@on-scan-q-r="onScanQR"
					extractParam="voucherID"
					:permissionTitle="t('scan_qr.camera_permission_title')"
					:permissionDescription="t('scan_qr.camera_permission_description')"></ScanQR>
			</div>

			<!-- <div v-if="voucherData && voucherData.voucherRedeemedItems && voucherData.voucherRedeemedItems.length" class="flex grow flex-col items-center justify-center px-[34px]">
        <div v-for="item in voucherData.voucherRedeemedItems" :key="item">
          {{ item.item_extra_data.event_name }} - {{ item.item_extra_data.ticket_type_name }}
        </div>
      </div> -->

			<div class="flex flex-col w-full px-[34px] align-center mb-8">
				<ion-button
					class="w-full hover:bg-white hover:rounded-full"
					expand="full"
					size="large"
					shape="round"
					:disabled="isLoadingVoucher || !voucherText"
					@click="redeemVoucher(voucherText)">
					<div class="flex w-full items-center justify-between">
						{{ t('general.continue') }}
						<ion-icon :icon="arrowForwardOutline" class="text-dark-blue mr-2"></ion-icon>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped></style>
