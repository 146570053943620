import { computed, ref, watch } from 'vue';
import router from '@/router';
import { storeToRefs } from 'pinia';
import { useQueryClient, useMutation } from '@tanstack/vue-query';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';
import { useI18n } from 'vue-i18n';

import { AxiosError } from 'axios';
import { TenantStyles } from '@/shared/interfaces/tenant';
import { useAuthStore } from '@/stores/auth/authStore';
import authApi from '@/api/authApi';
import type { SocialProvider, UserLoginData, UserToken } from '@/shared/interfaces/user';
import useAlert from '@/shared/composables/useAlert';
import useAuthHelpers from '@/modules/auth/composables/useAuthHelpers';
import useLoading from '@/shared/composables/useLoading';
import useToast from '@/shared/composables/useToast';
import { captureMessage } from '@/sentry';
import useQueryCacheHelper from '@/shared/composables/useQueryCacheHelper';
import useNotifications from '@/shared/composables/useNotifications';

// Init
const { presentAlert } = useAlert();
const { presentLoading } = useLoading();
const { presentToast } = useToast();
const { requestPushNotificationsPermission } = useNotifications();

// Register user
const registerUser = async (registerData: any): Promise<UserToken> => {
	// Get device info
	const info = await Device.getInfo();

	// Prepare formData
	const formData = new FormData();
	Object.keys(registerData).forEach((key: string) => {
		if (key === 'profile_image') {
			const blobFile = registerData[key];
			if (blobFile) {
				const fileName = `${blobFile.name}.${blobFile.extension}`;
				const file = new File([blobFile.blob], fileName);
				formData.append('profile_image', file, fileName);
			}
		} else {
			formData.append(key, registerData[key]);
		}
	});
	formData.append('device_name', info.model);

	// Send POST
	const { data } = await authApi.post('/auth/register', formData);
	return data;
};

// useAuth
const useAuth = () => {
	// Init
	const { deleteToken, getGuestUserId, getRedirect, isAuthenticated, resetGuestUser, saveToken, setRedirect } =
		useAuthStore();
	const { t } = useI18n();
	const queryClient = useQueryClient();
	const userProfile = queryClient.getQueryCache().find(['userProfile']);
	const logoutLoading = ref();
	const guestUserId = getGuestUserId();
	const { removeCaches } = useQueryCacheHelper();

	// Email login
	const loginUser = async (loginData: { userData: UserLoginData; message: string }): Promise<UserToken> => {
		// Init
		const loading = await presentLoading(loginData.message);

		// Get device info
		const info = await Device.getInfo();

		// Send POST
		try {
			const { data } = await authApi.post<UserToken>('/auth/login', {
				...loginData.userData,
				device_name: info.model,
			});
			await loading.dismiss();
			if (data) {
				return data;
			}

			throw new Error(t('auth.sign_in_email_page.errors.username_password'));
		} catch (error: any) {
			await loading.dismiss();
			throw new Error(t('auth.sign_in_email_page.errors.username_password'));
		}
	};

	// Login with RFID
	const loginUserWithRfid = async (encrypted_rfid_tag: string, message: string) => {
		// Init
		const authStore = useAuthStore();
		const { user } = storeToRefs(authStore);

		const { data } = await authApi.post<any>('auth/login-rfid-tag', { encrypted_rfid_tag: encrypted_rfid_tag });

		// If login is a success, save token in the store
		user.value.token = data && data.token ? data.token : null;

		// Finally, redirect the user if we have a token
		if (user && user.value.token) {
			await router.push('/dashboard');
		} else {
			captureMessage(`Error saving token: ${user}`);
		}
	};

	const checkIfEmailExists = async (email: string) => {
		const { data } = await authApi.post<any>(`auth/email-exists?email=${email}`);
		return data;
	};

	const checkIfPhoneExists = async (phoneNumber: string) => {
		const { data } = await authApi.post<any>(`auth/phone-exists?phone_number=${phoneNumber}`);
		return data;
	};

	// Get phone verification code
	const verifyPhone = async (loginData: { userData: UserLoginData; message: string; recaptchaToken: string }) => {
		const info = await Device.getInfo();

		const { data } = await authApi.post<any>('/auth/verify-phone', {
			...loginData.userData,
			device_name: info.model,
			recaptcha_token: loginData.recaptchaToken,
		});

		if (data && data.uuid) {
			await router.push({ path: `/auth/signinphoneverificationcode/${data.uuid}` });
		}
	};

	// Login with phone
	const loginUserWithPhone = async (loginData: { userData: UserLoginData; message: string }) => {
		const authStore = useAuthStore();
		const { user } = storeToRefs(authStore);
		const info = await Device.getInfo();

		const { data } = await authApi.post<any>('/auth/login-phone', {
			...loginData.userData,
			device_name: info.model,
		});

		user.value.token = data && data.token ? data.token : null;

		if (user && user.value.token) {
			await router.push('/dashboard');
		} else {
			captureMessage(`Error saving token: ${user}`);
		}
	};

	// Register with phone
	const registerUserWithPhone = async (loginData: { userData: UserLoginData; recaptchaToken: string }) => {
		const info = await Device.getInfo();

		const { data } = await authApi.post<any>('/auth/register-phone', {
			...loginData.userData,
			device_name: info.model,
			recaptcha_token: loginData.recaptchaToken,
		});

		if (data && data.uuid) {
			await router.push({ path: `/auth/signinphoneverificationcode/${data.uuid}` });
		} else {
			captureMessage(`Error register user with phone: ${data}`);
		}
	};

	// Logout
	const logoutUser = async (message: string) => {
		const authStore = useAuthStore();
		const { user } = storeToRefs(authStore);
		logoutLoading.value = await presentLoading(message);
		try {
			await authApi.post<any>('/auth/logout', { token: user.value.token });
		} catch (e: any) {
			logoutLoading.value.dismiss();
		}
	};

	// oAuth
	const oAuthLogin = async (type: SocialProvider = 'facebook', tenantStyles: TenantStyles) => {
		const authStore = useAuthStore();
		const { resetGuestUser } = useAuthStore();
		const { handleNativeSocialLogin, handleWebSocialLogin } = useAuthHelpers();

		const { user } = storeToRefs(authStore);

		if (Capacitor.isNativePlatform()) {
			const token = await handleNativeSocialLogin(type, t);
			user.value.token = token;
			setTimeout(async () => {
				await requestPushNotificationsPermission();
			}, 1000);
		} else {
			await handleWebSocialLogin(type, tenantStyles);
		}

		return user;
	};

	// Check if authenticated, otherwise call logout
	if (!isAuthenticated() && userProfile) {
		// Token was not found, remove everything from the cache
		removeCaches();
		// queryClient.removeQueries({ queryKey: ['userProfile'] });
	} else if (isAuthenticated() && guestUserId) {
		// User is authenticated but guest user id still exists
		// Delete it as it is not needed
		resetGuestUser();
	}

	// Register
	const {
		data: registerData,
		mutate: registerMutate,
		isLoading: isLoadingRegister,
		isSuccess: isSuccessRegister,
		isError: isErrorRegister,
		error: errorRegister,
	} = useMutation(registerUser);

	// Email register: Listen for change
	watch(isSuccessRegister, async () => {
		if (isSuccessRegister.value && registerData.value) {
			// If login is a success, save token in the store
			const user = await saveToken(registerData.value);
			// Finally, redirect the user if we have a token
			if (user && user.token) {
				await router.push('/profile/additionalconfig');
			} else {
				captureMessage(`Error saving token: ${user}`);
			}
		}
	});

	watch(isErrorRegister, async () => {
		if (isErrorRegister.value && errorRegister.value) {
			if (errorRegister.value instanceof AxiosError && errorRegister.value.response) {
				const errorStatus: any = errorRegister.value ? errorRegister.value.response.status : '';
				const errors: any = errorRegister.value ? errorRegister.value.response.data.errors : '';
				if (errorStatus === 422 && errors.email && errors?.email[0] === 'The email has already been taken.') {
					const email: string = errorRegister.value.config?.data.get('email');

					await presentAlert({
						header: t('auth.sign_up.account_exists_already'),
						message: t('auth.sign_up.account_exists_already_description', {
							email_addresss: email,
						}),
						buttons: [
							{
								text: t('auth.sign_up.login'),
								role: 'ok',
								cssClass: 'secondary',
								handler: () => {
									router.push('/auth/signinwithemail');
								},
							},
							{
								text: t('auth.sign_up.cancel'),
								role: 'cancel',
								cssClass: 'secondary',
								// handler: this.cancelDeletion.bind(this),
							},
							{
								text: t('auth.sign_up.request_password'),
								role: 'ok',
								handler: () => {
									router.push(`/profile/password/reset/request?email=${email}`);
								},
							},
						],
					});
				} else if (errorStatus === 422) {
					await presentAlert({
						header: t('auth.sign_up.error'),
						message: Object.keys(errors)
							.map((errorKey) => errors[errorKey])
							.join('<br>'),
						buttons: [
							{
								text: t('general.ok'),
								role: 'ok',
							},
						],
					});
				} else {
					await presentToast('top', errorRegister.value.message, 5000, 'danger');
				}
			}
		}
	});

	// Login
	const {
		data: loginData,
		mutate: loginMutate,
		isLoading: isLoadingLogin,
		isSuccess: isSuccessLogin,
		isError: isErrorLogin,
		error: errorLogin,
	} = useMutation(loginUser);

	// Email login: Listen for change
	watch(isSuccessLogin, () => {
		if (isSuccessLogin.value && loginData.value) {
			// If login is a success, save token in the store
			saveToken(loginData.value);
			requestPushNotificationsPermission();
			// Reset guest user
			resetGuestUser();
		}
	});

	watch(isErrorLogin, async () => {
		if (isErrorLogin.value && errorLogin.value) {
			const errorMessage = errorLogin.value.toString();
			await presentToast('top', errorMessage, 5000, 'danger');
		}
	});

	// Logout
	const { mutate: logoutMutate, isSuccess: isSuccessLogout } = useMutation(logoutUser);

	// Delete token and redirect only if logout was a success
	watch(isSuccessLogout, async () => {
		if (isSuccessLogout.value) {
			await deleteToken();
			removeCaches();
			logoutLoading.value.dismiss();
		}
	});

	// Return
	return {
		checkIfEmailExists,
		checkIfPhoneExists,

		// Register user
		registerUser: registerMutate,
		registerData,
		isLoadingRegister: computed(() => isLoadingRegister.value),
		isSuccessRegister: computed(() => isSuccessRegister.value),
		isErrorRegister: computed(() => isErrorRegister.value),
		errorRegister,

		// Login user
		loginUser: loginMutate,
		loginData,
		isLoadingLogin: computed(() => isLoadingLogin.value),
		isSuccessLogin: computed(() => isSuccessLogin.value),
		isErrorLogin: computed(() => isErrorLogin.value),
		loginUserWithRfid,
		errorLogin,
		verifyPhone,
		loginUserWithPhone,
		registerUserWithPhone,

		// Login oAuth
		oAuthLogin,

		// Logout
		logoutUser: logoutMutate,
		isSuccessLogout,

		// Redirect
		getRedirect,
		setRedirect,
	};
};

export default useAuth;
