import { computed, ref, watch } from 'vue';
import { useInfiniteQuery, useQueryClient } from '@tanstack/vue-query';

import { anykrowdApi } from '@/api/anykrowdApi';
import { Feed } from '@/shared/interfaces/feed';
import useStorageService from '@/shared/composables/useStorageService';

// Get feed
const getFeed = async (pageParam: number): Promise<Feed[]> => {
	const { data } = await anykrowdApi.get<Feed[]>('/feed/dashboard', false, { params: { page: pageParam } });
	return data;
};

// useFeed
const useFeed = (isFavorite: boolean, noData?: boolean) => {
	// Init
	const feedList = ref<Feed[] | any>();
	const feedListLength = ref(0);
	const params: { [param: string]: any } = {};
	const queryClient = useQueryClient();
	const { get, set } = useStorageService();

	if (isFavorite) {
		params.favorites_only = true;
	}

	if (noData) {
		params.search = 'dontfindanything';
	}

	const {
		data: feedListData,
		fetchNextPage,
		hasNextPage,
		isFetching,
		refetch: refetchFeedDashboard,
		remove: removeFeedDashboardResultsFromCache,
	} = useInfiniteQuery({
		queryKey: ['feedDashboard'],
		queryFn: ({ pageParam = 1 }) => getFeed(pageParam),
		getNextPageParam: (lastPage, pages) => {
			if (Array.isArray(lastPage)) {
				const filteredLastPage = lastPage?.filter(
					(feed) =>
						feed.type === 'event_now' ||
						feed.type === 'event_soon' ||
						feed.type === 'event' ||
						feed.type === 'post',
				);
				if (filteredLastPage.length < 25) {
					return undefined;
				} else if (filteredLastPage.length === 25) {
					return pages.flat().length / 25 + 1;
				}
			}

			return undefined;
		},
		retry: 3,
	});

	watch(
		feedListData,
		() => {
			if (feedListData.value && feedListData.value.pages) {
				feedList.value = [
					...feedListData.value.pages
						.flat()
						.filter(
							(feed) =>
								feed.type === 'main_alert' ||
								feed.type === 'event_now' ||
								feed.type === 'event_soon' ||
								feed.type === 'event' ||
								feed.type === 'post',
						),
				];
				feedListLength.value = feedList.value.filter((feed: any) => feed.type !== 'main_alert').length;

				// Update cache
				set('feedDashboard', feedListData.value);
			}
		},
		{ immediate: true },
	);

	return {
		feedList,
		feedListLength,
		fetchNextPage,
		getFeed,
		hasNextPage,
		isFetchingFeedDashboard: computed(() => isFetching.value),
		refetchFeedDashboard,
		removeFeedDashboardResultsFromCache,
	};
};

export default useFeed;
