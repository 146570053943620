<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { IonButton, IonIcon } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { cameraOutline, cameraReverseOutline, closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TopBar from '@/shared/components/TopBar.vue';
import useCamera from '@/shared/composables/useCamera';
import useNativeSettings from '@/shared/composables/useNativeSettings';

// Props
const props = defineProps({
	extractParam: { type: String },
	startCamera: { type: Boolean, default: false },
	permissionTitle: { type: String, required: true },
	permissionDescription: { type: String, required: false },
});

// Init
const { t } = useI18n();
const route = useRoute();
const disableScanButton = ref(false);
const isReverseCameraPresent = ref(false);
const {
	cameraPermission,
	canvasElement,
	canClose,
	captureImage,
	checkPermission,
	fileinputElement,
	handleFile,
	reverseCamera,
	reverseCameraPresent,
	scanActive,
	scanResult,
	scanQr,
	stopScan,
	videoElement,
} = useCamera();

const { openAppSettings } = useNativeSettings();
const emit = defineEmits(['onScanQR']);

// onMountedHook
onMounted(async () => {
	// checkPermission
	cameraPermission.value = await checkPermission();

	// Start camera
	if (props.startCamera) {
		disableScanButton.value = false;
		await scanQr();
	}

	if (route.query.voucherID) {
		const voucherID = route.query.voucherID;
		emit('onScanQR', voucherID);
	}

	// Reverse camera enabled
	setTimeout(async () => {
		isReverseCameraPresent.value = await reverseCameraPresent();
	}, 500);
});

// Watch changes on scanResult
watch(scanResult, () => {
	if (props.extractParam) {
		const url = new URL(scanResult.value.replaceAll('/#', ''));
		const param = url.searchParams.get(props.extractParam);
		emit('onScanQR', param);
	} else {
		emit('onScanQR', scanResult.value);
	}
});
</script>

<template>
	<div>
		<div
			v-if="cameraPermission === 'granted' && scanActive"
			class="absolute flex flex-col justify-between top-0 left-0 w-full h-full bg-white z-10">
			<TopBar class="px-[34px]">
				<template #left>
					<div v-if="canClose" class="flex items-center justify-start cursor-pointer" @click="stopScan()">
						<ion-icon :icon="closeOutline" class="text-anykrowd-dark"></ion-icon>
					</div>
				</template>
			</TopBar>
			<div class="flex relative items-center justify-center">
				<input
					ref="fileinputElement"
					type="file"
					accept="image/*;capture=camera"
					hidden
					@change="handleFile(($event.target! as HTMLInputElement).files!)" />
				<video
					ref="videoElement"
					class="w-full h-full z-10 max-w-[600px] player"
					width="100"
					height="100"
					:controls="false"
					playsinline
					autoplay></video>
				<canvas ref="canvasElement" v-show="false"></canvas>
			</div>
			<div>
				<ion-button
					v-if="Capacitor.isNativePlatform() && isReverseCameraPresent"
					class="flex z-10 h-[64px] no-border bg-white"
					fill="outline"
					@click="reverseCamera()">
					<ion-icon :icon="cameraReverseOutline" class="text-anykrowd-dark" size="large"></ion-icon>
				</ion-button>
				<ion-button
					class="h-[64px] no-border bg-white ion-no-margin ion-no-padding"
					expand="full"
					fill="outline"
					@click="captureImage()">
					{{ t('scan_qr.capture_image') }}
				</ion-button>
				<ion-button
					class="h-[64px] no-border bg-anykrowd-dark-purple ion-no-margin ion-no-padding"
					expand="full"
					fill="outline"
					@click="stopScan()"
					color="primary">
					{{ t('scan_qr.stop_scan') }}
				</ion-button>
			</div>
		</div>

		<div class="flex items-center justify-center mb-4">
			<ion-button
				:disabled="!cameraPermission || cameraPermission === 'denied' || disableScanButton"
				fill="outline"
				shape="round"
				@click="scanQr()"
				color="primary">
				<span class="font-sourcesanspro-semibold text-base">{{ t('scan_qr.use_camera') }}</span>
				<ion-icon class="cursor-pointer" :icon="cameraOutline" slot="end"></ion-icon>
			</ion-button>
		</div>

		<div
			v-if="cameraPermission === 'denied'"
			class="flex flex-col items-center justify-center p-4 backdrop-blur bg-white/10 rounded-md font-sourcesanspro-regular">
			<div class="mb-2">
				<span class="text-m2">{{ props.permissionTitle }}</span>
			</div>
			<p class="text-center text-sm2 text-white/60 mb-2">{{ props.permissionDescription }}</p>
			<ion-button v-if="Capacitor.isNativePlatform()" @click="openAppSettings()">{{
				t('scan_qr.open_settings')
			}}</ion-button>
		</div>
	</div>
</template>

<style lang="scss" scoped>
ion-button.no-border {
	--border-width: 0;
}
</style>
