import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "font-sourcesanspro-regular text-sm text-center text-white/50 leading-[18px]" }
const _hoisted_2 = { class: "flex items-center justify-center" }
const _hoisted_3 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-white" }
const _hoisted_4 = { class: "px-[34px] pt-14" }
const _hoisted_5 = { class: "flex justify-center mb-8" }
const _hoisted_6 = ["src"]
const _hoisted_7 = {
  key: 0,
  class: "flex flex-col mb-2"
}
const _hoisted_8 = { class: "font-sourcesanspro-semibold text-xs2 text-white/60 leading-none" }
const _hoisted_9 = { class: "flex text-base2 items-baseline" }
const _hoisted_10 = { class: "mr-2" }
const _hoisted_11 = { class: "font-sourcesanspro-semibold text-m2" }
const _hoisted_12 = {
  key: 1,
  class: "flex flex-wrap justify-between items-center mb-2"
}
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { class: "px-[34px]" }
const _hoisted_15 = {
  key: 0,
  class: "flex flex-col items-center justify-center mb-2 p-4 rounded backdrop-blur-xl bg-white/10 text-center"
}
const _hoisted_16 = { class: "font-sourcesanspro-semibold text-sm2" }
const _hoisted_17 = { class: "font-sourcesanspro-regular text-xs2" }
const _hoisted_18 = {
  key: 1,
  class: "grid grid-flow-col gap-2 justify-stretch mb-4 auto-cols-fr"
}
const _hoisted_19 = {
  key: 0,
  class: "backdrop-blur-xl bg-white/10 h-[80px] rounded cursor-pointer"
}
const _hoisted_20 = { class: "flex flex-col items-center justify-center" }
const _hoisted_21 = { class: "font-sourcesanspro-semibold text-sm2 text-white" }
const _hoisted_22 = { class: "font-sourcesanspro-regular text-xxs text-white" }
const _hoisted_23 = { class: "backdrop-blur-xl bg-white/10 h-[80px] rounded cursor-pointer" }
const _hoisted_24 = { class: "flex flex-col items-center justify-center" }
const _hoisted_25 = { class: "font-sourcesanspro-semibold text-sm2 text-white" }
const _hoisted_26 = { class: "font-sourcesanspro-regular text-xxs text-white" }
const _hoisted_27 = { class: "backdrop-blur-xl bg-white/10 h-[80px] rounded cursor-pointer" }
const _hoisted_28 = { class: "flex flex-col items-center justify-center" }
const _hoisted_29 = { class: "font-sourcesanspro-semibold text-sm2 text-white" }
const _hoisted_30 = { class: "font-sourcesanspro-regular text-xxs text-white" }
const _hoisted_31 = {
  key: 1,
  class: "backdrop-blur-xl bg-white/10 h-[80px] rounded cursor-pointer"
}
const _hoisted_32 = { class: "flex flex-col items-center justify-center" }
const _hoisted_33 = { class: "font-sourcesanspro-semibold text-sm2 text-white" }
const _hoisted_34 = { class: "font-sourcesanspro-regular text-xxs text-white" }
const _hoisted_35 = {
  id: "news",
  class: "mb-2"
}

import { onMounted, ref, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
import router from '@/router';
import { Capacitor } from '@capacitor/core';
import { Browser } from '@capacitor/browser';
import {
	isPlatform,
	IonButton,
	IonContent,
	IonFooter,
	IonIcon,
	IonInfiniteScroll,
	IonInfiniteScrollContent,
	IonRefresher,
	IonRefresherContent,
	InfiniteScrollCustomEvent,
} from '@ionic/vue';
import {
	closeOutline,
	fastFoodOutline,
	linkOutline,
	ticketOutline,
	qrCodeOutline,
	walletOutline,
	shareSocialOutline,
} from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { Event } from '@/shared/interfaces/event';
import { FeedTypeEnum } from '@/enums/values.enum';
import { Post } from '@/shared/interfaces/post';
import { useAuthStore } from '@/stores/auth/authStore';
import FeedItem from '@/shared/components/feed/FeedItem.vue';
import ModalOne from '@/shared/components/ui/ModalOne.vue';
import NavigationBar from '@/shared/components/ui/NavigationBar.vue';
import NewsHeader from '@/modules/news/components/NewsHeader.vue';
import useAuth from '@/modules/auth/composables/useAuth';
import useEvents from '@/modules/events/composables/useEvents';
import useFavorite from '@/shared/composables/useFavorite';
import useFeed from '@/modules/dashboard/composables/useFeed';
import usePosts from '@/modules/news/composables/usePosts';
import usePreload from '@/shared/composables/usePreload';
import useRegistrationForm from '@/modules/auth/composables/useRegistrationForm';
import useRfid from '@/modules/rfid/composables/useRfid';
import useSelfOrderingGetSpotId from '@/modules/self-ordering/components/useSelfOrderingGetSpotId';
import useTenant from '@/shared/composables/useTenant';
import useToast from '@/shared/composables/useToast';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import WalletBox from '@/shared/components/WalletBox.vue';
import YourId from '@/modules/dashboard/components/YourId.vue';
import { useAssetsStore } from '@/stores/shared/assetsStore';
import { Wallet } from '@/shared/interfaces/balance';
import useNotifications from '@/shared/composables/useNotifications';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const assetStore = useAssetsStore();
const authStore = useAuthStore();
const { getRedirect, setRedirect } = useAuth();
const { presentToast } = useToast();
const { getAutoOpenSelfOrder } = useSelfOrderingGetSpotId();
const { autoOpenSelfOrder } = getAutoOpenSelfOrder();
const isYourIdModalOpen = ref(false);
const isMainAlertModalOpen = ref(false);
const { getUserProfile, shareGuestTopup } = useUserProfile();
const { isFetchedUserProfile, refetchUserProfile, userProfile } = getUserProfile();
const {
	feedList,
	feedListLength,
	fetchNextPage,
	hasNextPage,
	refetchFeedDashboard,
	removeFeedDashboardResultsFromCache,
} = useFeed(false);
const { toggleFavorite } = useFavorite();
const { getRfidTags } = useRfid();
const { rfidTags } = getRfidTags();
const { currentMainAlertPost, markMainAlertAsSeenInCache, postSetMainAlertAsSeen, setCurrentMainAlert } = usePosts();
const { preloadCache } = usePreload();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig, showLoggedData } = getTenantConfigAndStyles();
const { getRegistrationFormForAdditionalData } = useRegistrationForm();
const { registrationForm } = getRegistrationFormForAdditionalData();
const { getEventsCount } = useEvents();
const eventsTotal = ref();
const selectedWallet = ref<Wallet>();
const walletBox = ref();
const { requestPushNotificationsPermission } = useNotifications();

// Init
onMounted(async () => {
	if (await authStore.isAuthenticated()) {
		eventsTotal.value = await getEventsCount('', 'going');
		await requestPushNotificationsPermission();

		// Handle voucherID as query param
		const voucherID = route.query.voucherID;
		if (voucherID) {
			router.push({
				path: 'top-up/redeem',
				query: {
					voucherID,
				},
			});
		}
	}
});

// Handle main alert
watchEffect(async () => {
	if (!window.apiUrl.includes('localhost')) {
		await preloadCache();
	}
	if (tenantConfig.value && userProfile.value && feedList.value) {
		const mainAlertIdx = feedList.value.findIndex((feedItem) => feedItem?.type === FeedTypeEnum.main_alert);
		const userCanSeePopup: boolean = tenantConfig.value.enable_public_mode
			? true
			: userProfile.value?.profile_completed;

		// If public mode is enabled or user profile is completed and there is a main alert, then show it
		if (userCanSeePopup && mainAlertIdx !== -1) {
			const feedData = { ...(feedList.value[mainAlertIdx].data as any) };
			// Show main alert
			setCurrentMainAlert(feedData.post).then((post: Post | null) => {
				if (post) {
					showHideModal(true);
				}
			});
		}
	}
});

// Main Alert
const showHideModal = async (openState: boolean) => {
	if (!openState) {
		const mainAlertIdx = feedList.value!.findIndex((feedItem) => feedItem?.type === FeedTypeEnum.main_alert);

		if (mainAlertIdx !== -1) {
			const feedData = { ...(feedList.value![mainAlertIdx].data as any) };

			if (await authStore.isAuthenticated()) {
				// If user is authenticated we will alert the BE that is post has been closed
				postSetMainAlertAsSeen(feedData.post);
			} else if (tenantConfig && tenantConfig.value && tenantConfig.value.enable_public_mode) {
				// In public mode we will set this cache entry as seen when you're not authenticated
				markMainAlertAsSeenInCache(feedData.post);
			}
		}
	}
	isMainAlertModalOpen.value = openState;
};

const checkRedirect = () => {
	const redirect = getRedirect();
	if (redirect.callbackPage && redirect.id) {
		// reset redirection and redirect
		setRedirect({ callbackPage: '', id: '' });
		router.push({ path: `/${redirect.callbackPage}/${redirect.id}`, query: { continueLinking: 'true' } });
	}
};

watchEffect(async () => {
	await authStore.authenticateWithSavedToken();
	const isAuthenticated = authStore.isAuthenticated();
	if (isAuthenticated) {
		await refetchUserProfile();
		if (
			userProfile.value &&
			!userProfile.value?.profile_completed &&
			registrationForm.value &&
			registrationForm.value.data &&
			registrationForm.value.data.length > 0
		) {
			router.push('/profile/additionaldata');
		}
		checkRedirect();
	}
});

// goTo
const goTo = async (link: string) => {
	await router.push(link);
};

// Check auth
const checkAuth = async () => {
	if (!authStore.isAuthenticated()) {
		await presentToast('top', t('general.please_login_first'), 5000, 'danger');
		await router.push('/auth/signuphome');
		return false;
	} else {
		return true;
	}
};

// showHideYourId
const showHideYourId = async (openState: boolean) => {
	const authCheckPassed = await checkAuth();
	if (authCheckPassed) {
		isYourIdModalOpen.value = openState;
	}
};

// Refresh
const handleRefresh = async (event: any) => {
	removeFeedDashboardResultsFromCache();
	feedList.value = [];
	await refetchFeedDashboard();
	walletBox.value?.refetchWalletsData();
	event.target.complete();
};

const openWalletManage = async (selectedWallet: Wallet) => {
	const authCheckPassed = await checkAuth();
	if (authCheckPassed) {
		await router.push(`/wallet/manage/${selectedWallet.id}`);
	}
};

// openSelfOrder
const openSelfOrder = async () => {
	const authCheckPassed = await checkAuth();
	if (authCheckPassed) {
		if (autoOpenSelfOrder && autoOpenSelfOrder.value && autoOpenSelfOrder.value.auto_open_self_order) {
			await router.push(`/self-ordering/get-spot-id?spotid=${autoOpenSelfOrder.value.auto_open_self_order_qr}`);
		} else {
			await router.push('/self-ordering/get-spot-id');
		}
	}
};

// openPayconiqLink
const openPayconiqLink = () => {
	if (isPlatform('android')) {
		Browser.open({
			url: 'https://play.google.com/store/apps/details?id=mobi.inthepocket.bcmc.bancontact&hl=en&gl=US',
		});
	}

	if (isPlatform('ios')) {
		Browser.open({ url: 'https://apps.apple.com/be/app/payconiq-by-bancontact/id858371800' });
	}
};

// Infinite scroll
const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
	if (hasNextPage && hasNextPage.value) {
		fetchNextPage();
	}
	ev.target.complete();
};

const selectWallet = (wallet: Wallet) => {
	selectedWallet.value = wallet;
};

return (_ctx: any,_cache: any) => {
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(IonContent), null, {
      default: _withCtx(() => [
        _createVNode(ModalOne, {
          title: _unref(currentMainAlertPost)?.title,
          "is-open": isMainAlertModalOpen.value
        }, {
          content: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _withDirectives(_createElementVNode("div", null, null, 512), [
                [_directive_dompurify_html, _unref(currentMainAlertPost)?.content]
              ])
            ])
          ]),
          actions: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_unref(IonIcon), {
                  class: "text-[38px] text-black cursor-pointer",
                  icon: _unref(closeOutline),
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (showHideModal(false)))
                }, null, 8, ["icon"])
              ])
            ])
          ]),
          _: 1
        }, 8, ["title", "is-open"]),
        _createVNode(_unref(IonRefresher), {
          class: "mt-8",
          slot: "fixed",
          onIonRefresh: _cache[1] || (_cache[1] = ($event: any) => (handleRefresh($event)))
        }, {
          default: _withCtx(() => [
            _createVNode(_unref(IonRefresherContent))
          ]),
          _: 1
        }),
        _createVNode(YourId, {
          "is-modal-open": isYourIdModalOpen.value,
          onOnCloseModal: showHideYourId
        }, null, 8, ["is-modal-open"]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, [
            _createElementVNode("img", {
              src: _unref(assetStore).logoUrl,
              class: "max-w-[70%]"
            }, null, 8, _hoisted_6)
          ]),
          (_unref(showLoggedData)())
            ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, _toDisplayString(_unref(t)('dashboard.hello')), 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("div", _hoisted_10, [
                    _createElementVNode("span", _hoisted_11, _toDisplayString(_unref(userProfile)?.id ? _unref(userProfile).full_name : _unref(userProfile)?.firstname), 1)
                  ]),
                  (_unref(userProfile) && _unref(userProfile).id !== 0)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 0,
                        class: "font-sourcesanspro-regular underline text-xs2 cursor-pointer",
                        onClick: _cache[2] || (_cache[2] = ($event: any) => (goTo('/profile')))
                      }, _toDisplayString(_unref(t)('dashboard.edit')), 1))
                    : _createCommentVNode("", true),
                  (_unref(userProfile) && _unref(userProfile).id === 0)
                    ? (_openBlock(), _createElementBlock("span", {
                        key: 1,
                        class: "font-sourcesanspro-regular underline text-xs2 cursor-pointer",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (goTo('/auth/signuphome')))
                      }, _toDisplayString(_unref(t)('dashboard.login')), 1))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          (_unref(showLoggedData)() && _unref(userProfile) && _unref(isFetchedUserProfile) && _unref(userProfile).id !== 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                _createVNode(_unref(IonButton), {
                  class: "flex grow min-w-[142px] max-w-[142px] h-[35px] ion-no-margin mb-2 slim text-xs hover:bg-white hover:rounded-full",
                  shape: "round",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (goTo('/rfid')))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      class: "mr-1",
                      icon: _unref(linkOutline)
                    }, null, 8, ["icon"]),
                    _createTextVNode(" " + _toDisplayString(_unref(rfidTags).length) + _toDisplayString(_unref(t)('rfid.x_rfid')), 1)
                  ]),
                  _: 1
                }),
                (
						_unref(userProfile) &&
						_unref(isFetchedUserProfile) &&
						_unref(userProfile).id !== 0 &&
						eventsTotal.value &&
						eventsTotal.value.count &&
						_unref(tenantConfig) &&
						!_unref(tenantConfig)?.hide_tickets
					)
                  ? (_openBlock(), _createBlock(_unref(IonButton), {
                      key: 0,
                      class: "flex grow min-w-[142px] max-w-[142px] h-[35px] ion-no-margin mb-2 slim text-xs hover:bg-white hover:rounded-full",
                      shape: "round",
                      onClick: _cache[5] || (_cache[5] = ($event: any) => (goTo('/events/?eventType=going')))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_unref(IonIcon), {
                          class: "mr-1",
                          icon: _unref(ticketOutline)
                        }, null, 8, ["icon"]),
                        _createTextVNode(" " + _toDisplayString(eventsTotal.value.count) + " " + _toDisplayString(_unref(t)('ticket.tickets')), 1)
                      ]),
                      _: 1
                    }))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true)
        ]),
        (_unref(showLoggedData)())
          ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
              (_unref(tenantConfig))
                ? (_openBlock(), _createBlock(WalletBox, {
                    key: 0,
                    ref_key: "walletBox",
                    ref: walletBox,
                    user: `${_unref(userProfile)?.firstname} ${_unref(userProfile)?.lastname}`,
                    onOnWalletSet: selectWallet
                  }, null, 8, ["user"]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_14, [
          (
					_unref(showLoggedData)() &&
					_unref(Capacitor).isNativePlatform() &&
					_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode
				)
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(t)('dashboard.payconiq.dont_have_the_app')), 1),
                _createElementVNode("span", _hoisted_17, _toDisplayString(_unref(t)('dashboard.payconiq.install')), 1),
                _createVNode(_unref(IonButton), {
                  class: "flex text-sm2 h-[48px] payconiq-button",
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (openPayconiqLink()))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_unref(t)('dashboard.payconiq.button_text')), 1)
                  ]),
                  _: 1
                })
              ]))
            : _createCommentVNode("", true),
          (_unref(showLoggedData)() && !_unref(tenantConfig)?.hide_wallet)
            ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                (_unref(tenantConfig)?.self_order_activated)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                      _createVNode(_unref(IonButton), {
                        class: "flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent",
                        onClick: _cache[7] || (_cache[7] = ($event: any) => (openSelfOrder()))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_20, [
                            _createVNode(_unref(IonIcon), {
                              class: "mt-1",
                              icon: _unref(fastFoodOutline)
                            }, null, 8, ["icon"]),
                            _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(t)('self_ordering.title')), 1),
                            _createElementVNode("span", _hoisted_22, _toDisplayString(_unref(t)('self_ordering.subtitle')), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_23, [
                  _createVNode(_unref(IonButton), {
                    class: "flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent",
                    onClick: _cache[8] || (_cache[8] = ($event: any) => (openWalletManage(selectedWallet.value)))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_24, [
                        _createVNode(_unref(IonIcon), {
                          class: "mt-1",
                          icon: _unref(walletOutline)
                        }, null, 8, ["icon"]),
                        _createElementVNode("span", _hoisted_25, _toDisplayString(_unref(t)('wallet.manage_wallet.title')), 1),
                        _createElementVNode("span", _hoisted_26, _toDisplayString(_unref(t)('wallet.manage_wallet.description')), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                _createElementVNode("div", _hoisted_27, [
                  _createVNode(_unref(IonButton), {
                    class: "flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent",
                    onClick: _cache[9] || (_cache[9] = ($event: any) => (showHideYourId(true)))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_28, [
                        _createVNode(_unref(IonIcon), {
                          class: "mt-1",
                          icon: _unref(qrCodeOutline)
                        }, null, 8, ["icon"]),
                        _createElementVNode("span", _hoisted_29, _toDisplayString(_unref(t)('your_id.title')), 1),
                        _createElementVNode("span", _hoisted_30, _toDisplayString(_unref(t)('your_id.description')), 1)
                      ])
                    ]),
                    _: 1
                  })
                ]),
                (_unref(tenantConfig) && _unref(tenantConfig).guest_wallet_topup_clientx)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                      _createVNode(_unref(IonButton), {
                        class: "flex flex-col items-center justify-center w-full h-full ion-no-padding ion-no-margin transparent",
                        onClick: _cache[10] || (_cache[10] = ($event: any) => (_unref(shareGuestTopup)()))
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_32, [
                            _createVNode(_unref(IonIcon), {
                              class: "mt-1",
                              icon: _unref(shareSocialOutline)
                            }, null, 8, ["icon"]),
                            _createElementVNode("span", _hoisted_33, _toDisplayString(_unref(t)('guest_top_up.share.title')), 1),
                            _createElementVNode("span", _hoisted_34, _toDisplayString(_unref(t)('guest_top_up.share.subtitle')), 1)
                          ])
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_35, [
            (_unref(feedListLength))
              ? (_openBlock(), _createBlock(NewsHeader, {
                  key: 0,
                  class: "mb-2"
                }))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(feedList)?.filter(
						(feed) =>
							feed.type === 'event_now' ||
							feed.type === 'event_soon' ||
							feed.type === 'event' ||
							feed.type === 'post',
					), (item, idx) => {
              return (_openBlock(), _createBlock(FeedItem, {
                onOnFavoriteClicked: _unref(toggleFavorite),
                key: idx,
                id: item.data.id,
                "canceled-at": 
						item.type === 'event' || item.type === 'event_now' ? (item.data as Event).canceled_at : null
					,
                favorite: item.data.is_favorite,
                summary: item.data.summary ? item.data.summary : '',
                image: item.data.main_image_url ? item.data.main_image_url : '',
                link: `${item.type.includes('event') ? 'events' : 'news'}/${item.data.id}`,
                type: _unref(FeedTypeEnum)[item.type],
                "location-address": item.type.includes('event') ? (item.data as Event).address : '',
                "happening-now": item.type === 'event_now',
                "happening-soon": item.type === 'event_soon',
                "is-event": item.type === 'event' || item.type === 'event_now' || item.type === 'event_soon',
                date: item.type.includes('event') ? (item.data as Event).start_datetime : '',
                "show-logged-data": _unref(showLoggedData)(),
                title: 
						item.type === 'event_now' || item.type === 'event_soon' || item.type === 'event'
							? (item.data as Event).name
							: (item.data as Post).title
					
              }, null, 8, ["onOnFavoriteClicked", "id", "canceled-at", "favorite", "summary", "image", "link", "type", "location-address", "happening-now", "happening-soon", "is-event", "date", "show-logged-data", "title"]))
            }), 128))
          ]),
          _createVNode(_unref(IonInfiniteScroll), { onIonInfinite: ionInfinite }, {
            default: _withCtx(() => [
              _createVNode(_unref(IonInfiniteScrollContent))
            ]),
            _: 1
          }),
          _cache[11] || (_cache[11] = _createElementVNode("div", { class: "h-[100px]" }, " ", -1))
        ])
      ]),
      _: 1
    }),
    _createVNode(_unref(IonFooter), null, {
      default: _withCtx(() => [
        _createVNode(NavigationBar, { "item-active": "dashboard" })
      ]),
      _: 1
    })
  ], 64))
}
}

})