<script setup lang="ts">
import { watch } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon } from '@ionic/vue';
import * as Yup from 'yup';
import { chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';
import { StringSchema } from 'yup';
import { isValid } from 'iban-validator-js';

import { useSharedStore } from '@/stores/shared/sharedStore';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useCustomForm from '@/shared/composables/useCustomForm';
import useTenant from '@/shared/composables/useTenant';

// Init
const { t } = useI18n();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const sharedStore = useSharedStore();
const route = useRoute();
const rfidTagUuid = route.query.rfidTagUuid ? route.query.rfidTagUuid.toString() : '';
const encryptedrfidtag = route.params.encryptedrfidtag?.toString();
const isVoucher = route.query.isVoucher ? route.query.isVoucher.toString() : '';
const voucherCode = isVoucher ? route.params.encryptedrfidtag?.toString() : '';

Yup.addMethod<StringSchema>(Yup.string, 'validIban', function () {
	return Yup.string().test('test-valid-iban', 'Not valid IBAN number', (iban) => {
		iban = iban?.replaceAll(' ', '');
		return iban !== '' && iban !== undefined && iban !== null && isValid(iban);
	});
});

// Using yup to generate a validation schema
const schema = Yup.object().shape({
	firstname: Yup.string().required(),
	lastname: Yup.string().required(),
	email: Yup.string().matches(sharedStore.EMAIL_REGX, t('auth.sign_in_email_page.errors.email')).required(),
	iban_number: Yup.string().when('amount', ([amount], schema) => {
		return Yup.string().validIban('no valid iban').required('Field is required');
	}),
	// encrypted_rfid_tag: Yup.string().optional(),
	// rfid_tag_uuid: Yup.string().optional(),
	// voucher_code: Yup.string().optional(),
});

// useCustomForm
const { isFormValid, isSuccess, onSubmit, onInputChange } = useCustomForm(
	schema,
	'/rfid-tags/refund',
	'post',
	'',
	'',
	5000,
	{
		overwriteDefaultResponseMessage: true,
		responseField: 'message',
	},
);

watch(isSuccess, async () => {
	await router.back();
});

// Go back
const goBack = () => {
	router.back();
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col justify-between h-full">
			<div class="px-[34px] w-full">
				<TopBar>
					<template #left>
						<div class="flex items-center justify-start">
							<ion-button class="ion-no-margin ion-no-padding" fill="clear" @click="goBack()">
								<ion-icon :icon="chevronBackOutline" class="text-white"></ion-icon>
							</ion-button>
						</div>
					</template>

					<template #center>
						<div class="flex justify-center w-full font-sourcesanspro-semibold text-l2">
							{{ $t('rfid.guest.refund') }}
						</div>
					</template>
				</TopBar>

				<div
					v-if="tenantConfig && tenantConfig.refund_message"
					class="mb-2 font-sourcesanspro-regular text-regular text-white/60">
					<div
						v-dompurify-html="tenantConfig.refund_message"
						class="flex flex-col p-4 mt-2 mb-6 bg-white/10 rounded-lg"></div>
				</div>

				<div class="relative mb-4">
					<form id="resetPasswordForm" :validation-schema="schema" @submit="onSubmit">
						<TextInput
							wrapper-class="flex flex-wrap mb-2 border border-solid justify-left item-center focus-within:background-white-15"
							label="First name"
							name="firstname"
							type="text"
							placeholder="First name"
							@inputChanged="onInputChange" />
						<TextInput
							wrapper-class="flex flex-wrap mb-2 border border-solid justify-left item-center focus-within:background-white-15"
							label="Last name"
							name="lastname"
							type="text"
							placeholder="Last name"
							@inputChanged="onInputChange" />
						<TextInput
							wrapper-class="flex flex-wrap mb-2 border border-solid justify-left item-center focus-within:background-white-15"
							label="Email"
							name="email"
							type="text"
							placeholder="Email"
							@inputChanged="onInputChange" />
						<TextInput
							wrapper-class="flex flex-wrap mb-3 border border-solid justify-left item-center focus-within:background-white-15"
							label="IBAN"
							name="iban_number"
							placeholder="IBAN"
							@input-changed="onInputChange" />
						<TextInput
							wrapper-class="flex flex-wrap mb-3 border border-solid justify-left item-center focus-within:background-white-15"
							:value="encryptedrfidtag"
							name="encrypted_rfid_tag"
							type="hidden"
							@inputChanged="onInputChange" />
						<TextInput
							wrapper-class="flex flex-wrap mb-3 border border-solid justify-left item-center focus-within:background-white-15"
							:value="rfidTagUuid"
							name="rfid_tag_uuid"
							type="hidden"
							@inputChanged="onInputChange" />
						<TextInput
							wrapper-class="flex flex-wrap mb-3 border border-solid justify-left item-center focus-within:background-white-15"
							:value="voucherCode"
							name="voucher_code"
							type="hidden"
							@inputChanged="onInputChange" />
					</form>
				</div>
			</div>

			<div class="px-[34px] mb-[34px]">
				<ion-button
					class="w-full hover:bg-white hover:rounded-full"
					:disabled="!isFormValid"
					expand="full"
					form="resetPasswordForm"
					size="large"
					shape="round"
					type="submit">
					<div class="flex items-center justify-center w-full">
						{{ $t('profile.password_reset.forgot_password_submit') }}
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-list {
	background: transparent;
}
</style>
