import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full items-center justify-center mb-4" }
const _hoisted_2 = { class: "flex items-center justify-center w-[60px] h-[60px] rounded-full bg-anykrowd-gray/30 mb-4" }
const _hoisted_3 = { class: "font-sourcesanspro-semibold text-lg" }
const _hoisted_4 = { class: "text-anykrowd-dark" }
const _hoisted_5 = { class: "flex flex-col h-full justify-between" }
const _hoisted_6 = { class: "flex items-center justify-start cursor-pointer" }
const _hoisted_7 = { class: "px-[34px] pt-14" }
const _hoisted_8 = { class: "icon flex justify-center mb-4" }
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "mb-4 font-sourcesanspro-semibold text-white text-lg text-center" }
const _hoisted_11 = { class: "max-w-xs mx-auto mb-8 text-white text-base text-center" }
const _hoisted_12 = { class: "flex justify-center mb-2" }
const _hoisted_13 = { class: "flex justify-center mb-4 font-sourcesanspro-semibold text-base text-right" }
const _hoisted_14 = {
  key: 0,
  class: "flex justify-center mb-8"
}
const _hoisted_15 = {
  key: 1,
  class: "separator mb-8"
}
const _hoisted_16 = { class: "font-sourcesanspro-regular text-base text-white" }
const _hoisted_17 = { class: "max-w-s mx-auto mt-4 mb-8 px-9 font-sourcesanspro-regular text-white text-sm text-center" }
const _hoisted_18 = { class: "w-full max-w-none" }

import { ref } from 'vue';
import router from '@/router';
import { IonContent, IonIcon } from '@ionic/vue';
import { chevronBackOutline, closeOutline, mailOutline, callOutline } from 'ionicons/icons';

import CustomButton from '@/shared/components/CustomButton.vue';
import ModalThree from '@/shared/components/ui/ModalThree.vue';
import SignInSignUpAlert from '@/shared/components/SignInSignUpAlert.vue';
import SocialLoginButtons from '@/modules/auth/components/SocialLoginButtons.vue';
import TopBar from '@/shared/components/TopBar.vue';
import useTenant from '@/shared/composables/useTenant';
import { useAssetsStore } from '@/stores/shared/assetsStore';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'SignInHomePage',
  setup(__props) {

const { getTenantConfigAndStyles } = useTenant();
const assetStore = useAssetsStore();
const { isSuccessTenantConfig, tenantConfig, anySocialLoginEnabled } = getTenantConfigAndStyles();

// Modals
const modalState = ref(false);
const modalType = ref('');
const setModal = (state: boolean, type?: string) => {
	modalState.value = state;
	modalType.value = type === 'tos' ? 'tos' : 'pp';
};

const onLoginSuccess = () => {
	router.push('/profile/additionaldata');
};

const goTo = async (link: string) => {
	await router.push(link);
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createVNode(ModalThree, { "is-open": modalState.value }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_unref(IonIcon), {
                class: "text-[38px] text-black cursor-pointer",
                icon: _unref(closeOutline),
                onClick: _cache[0] || (_cache[0] = ($event: any) => (setModal(false)))
              }, null, 8, ["icon"])
            ])
          ]),
          _createElementVNode("h1", _hoisted_3, _toDisplayString(modalType.value === 'tos' ? 'Terms and conditions' : 'Privacy Policy'), 1),
          _withDirectives(_createElementVNode("div", _hoisted_4, null, 512), [
            [_directive_dompurify_html, 
					modalType.value === 'tos' ? _unref(tenantConfig)!.terms_and_conditions : _unref(tenantConfig)!.privacy_policy
				]
          ])
        ]),
        _: 1
      }, 8, ["is-open"]),
      _createElementVNode("div", _hoisted_5, [
        (_unref(tenantConfig) && _unref(tenantConfig).enable_public_mode)
          ? (_openBlock(), _createBlock(TopBar, {
              key: 0,
              class: "px-[34px]"
            }, {
              left: _withCtx(() => [
                _createElementVNode("div", _hoisted_6, [
                  _createVNode(_component_router_link, {
                    to: "/dashboard",
                    class: "flex items-center justify-center"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_unref(IonIcon), {
                        icon: _unref(chevronBackOutline),
                        class: "text-white"
                      }, null, 8, ["icon"])
                    ]),
                    _: 1
                  })
                ])
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("img", {
              src: _unref(assetStore).logoUrl,
              class: "max-w-[70%]"
            }, null, 8, _hoisted_9)
          ]),
          _createElementVNode("h1", _hoisted_10, _toDisplayString(_unref(tenantConfig)?.welcome_title), 1),
          _createElementVNode("div", _hoisted_11, _toDisplayString(_unref(tenantConfig)?.welcome_line), 1),
          _createElementVNode("div", _hoisted_12, [
            _createVNode(CustomButton, {
              onOnClick: _cache[1] || (_cache[1] = ($event: any) => (goTo('/auth/signinwithemail'))),
              text: "auth.sign_in_home_page.logInEmail",
              iconLeft: _unref(mailOutline)
            }, null, 8, ["iconLeft"])
          ]),
          _createElementVNode("div", _hoisted_13, [
            _createVNode(_component_router_link, { to: "/profile/password/reset/request" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('auth.sign_in_email_page.forgot_password')), 1)
              ]),
              _: 1
            })
          ]),
          (_unref(tenantConfig)?.enable_phone_login)
            ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createVNode(CustomButton, {
                  onOnClick: _cache[2] || (_cache[2] = ($event: any) => (goTo('/auth/signinwithphone'))),
                  text: "auth.sign_in_home_page.logInPhone",
                  iconLeft: _unref(callOutline)
                }, null, 8, ["iconLeft"])
              ]))
            : _createCommentVNode("", true),
          (_unref(anySocialLoginEnabled)())
            ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                _createElementVNode("h5", null, [
                  _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.$t(`auth.sign_in_home_page.orSignUpWith`)), 1)
                ])
              ]))
            : _createCommentVNode("", true),
          _createVNode(SocialLoginButtons, { onOnLoginSuccess: onLoginSuccess }),
          _createElementVNode("p", _hoisted_17, [
            _createTextVNode(_toDisplayString(_ctx.$t('auth.sign_in_home_page.terms_continue')) + " ", 1),
            (_unref(isSuccessTenantConfig))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (setModal(true, 'tos'))),
                  target: "_blank",
                  class: "text-white underline"
                }, _toDisplayString(_ctx.$t('auth.sign_in_home_page.terms_of_use')), 1))
              : _createCommentVNode("", true),
            _createTextVNode(" " + _toDisplayString(_ctx.$t('auth.sign_in_home_page.terms_and_confirm')) + " ", 1),
            (_unref(isSuccessTenantConfig))
              ? (_openBlock(), _createElementBlock("a", {
                  key: 1,
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (setModal(true, 'pp'))),
                  target: "_blank",
                  class: "text-white underline"
                }, _toDisplayString(_ctx.$t('auth.sign_in_home_page.terms_privacy_policy')), 1))
              : _createCommentVNode("", true)
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createVNode(SignInSignUpAlert, {
            buttonText: "components.sign_up.buttonText",
            text: "components.sign_up.text",
            link: "/auth/signuphome"
          })
        ])
      ])
    ]),
    _: 1
  }))
}
}

})