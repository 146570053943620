import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, isRef as _isRef, vShow as _vShow, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center w-full max-w-none h-full" }
const _hoisted_2 = { class: "flex grow justify-center w-full font-sourcesanspro-semibold text-l2" }
const _hoisted_3 = {
  key: 0,
  class: "flex flex-col items-center justify-between w-full h-full"
}
const _hoisted_4 = { class: "w-full max-w-[600px] px-[34px]" }
const _hoisted_5 = { class: "w-full mb-2" }
const _hoisted_6 = {
  key: 1,
  class: "mb-6 flex flex-col items-start justify-center p-4 font-sourcesanspro-regular rounded-md bg-white/10 small font-sourcesanspro-regular text-base2 text-white"
}
const _hoisted_7 = { class: "mb-1 font-extrabold" }
const _hoisted_8 = {
  key: 2,
  class: "p-4 rounded-md bg-white/5 text-white/60"
}
const _hoisted_9 = {
  key: 0,
  class: "flex p-4 items-center justify-center max-w-[600px] h-full"
}
const _hoisted_10 = {
  key: 1,
  class: "flex flex-col items-center justify-start max-w-[600px]"
}
const _hoisted_11 = {
  key: 0,
  class: "flex items-center justify-center"
}
const _hoisted_12 = { class: "font-sourcesanspro-regular text-[40px] mr-2" }
const _hoisted_13 = { class: "mx-1" }
const _hoisted_14 = { class: "flex flex-col items-center justify-start font-color-text-50" }
const _hoisted_15 = {
  key: 0,
  class: "mb-2"
}
const _hoisted_16 = {
  key: 0,
  class: "font-sourcesanspro-regular text-l2"
}
const _hoisted_17 = {
  key: 1,
  class: "w-[249px] px-1 text-center font-sourcesanspro-regular text-base2 leading-[26px]"
}
const _hoisted_18 = {
  key: 2,
  class: "flex flex-col w-full max-w-[600px] align-center"
}
const _hoisted_19 = {
  key: 0,
  class: "flex flex-col items-center mb-8"
}
const _hoisted_20 = { class: "mb-4" }
const _hoisted_21 = { class: "font-sourcesanspro-regular text-sm2" }
const _hoisted_22 = { class: "flex mb-4" }
const _hoisted_23 = { key: 1 }
const _hoisted_24 = { class: "flex flex-col w-full p-4 items-center justify-center w-full backdrop-blur bg-white/20" }
const _hoisted_25 = { class: "w-full max-w-[600px]" }
const _hoisted_26 = {
  key: 0,
  class: "flex flex-col items-center mb-4"
}
const _hoisted_27 = { class: "font-sourcesanspro-regular text-base2" }
const _hoisted_28 = { class: "font-sourcesanspro-semibold text-base text-white/50 underline" }
const _hoisted_29 = {
  key: 0,
  class: "flex w-full items-center justify-between"
}
const _hoisted_30 = {
  key: 1,
  class: "flex w-full items-center justify-between"
}
const _hoisted_31 = ["src"]

import { onMounted, ref, watch, watchEffect } from 'vue';
import router from '@/router';
import { useRoute } from 'vue-router';
import {
	IonButton,
	IonContent,
	IonIcon,
	IonLabel,
	IonSegment,
	IonSegmentButton,
	IonSelect,
	IonSelectOption,
	IonSpinner,
} from '@ionic/vue';
import { TopUp } from '@/enums/values.enum';
import { arrowForwardOutline, chevronBackOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { Wallet } from '@/shared/interfaces/balance';
import { TransactionType, TransactionSubtype } from '@/enums/wallet.enum';
import { useSharedStore } from '@/stores/shared/sharedStore';
import { useTransactionStore } from '@/stores/transaction/transactionStore';
import { WalletPackage } from '@/shared/interfaces/wallet';
import hexRgb from 'hex-rgb';
import ItemWithContentStandalone from '@/shared/components/ui/ItemWithContentStandalone.vue';
import rgbHex from 'rgb-hex';
import searchUser from '@/modules/guest/composables/useGuestUser';
import TextInput from '@/shared/components/sign-up-forms/TextInput.vue';
import TopBar from '@/shared/components/TopBar.vue';
import type { Transaction } from '@/shared/interfaces/transaction';
import useRfidGuest from '@/modules/rfid/composables/useRfidGuest';
import useTenant from '@/shared/composables/useTenant';
import useTopUp from '@/modules/top-up/composables/useTopUp';
import useUserProfile from '@/modules/profile/composables/useUserProfile';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import useCurrencies from '@/shared/composables/useCurrencies';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'TopUpPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const encryptedRfidTag = route.query.id?.toString();
const loginToken = route.query.loginToken?.toString() || '';
const walletId = route.params.wallet_id?.toString();
const backToDashboard = route.query.backToDashboard?.toString();
const transactionStore = useTransactionStore();
const { setItemsAndTotal } = transactionStore;
const { getPackages, getMinimumTopUpAmount, getPreviousTopUpAmount } = useWallet();
const { minimumTopUp, isLoading } = getMinimumTopUpAmount();
const { previousTopUp } = getPreviousTopUpAmount(walletId, encryptedRfidTag || loginToken ? false : true);
const { refetchPackagesData, walletPackages } = getPackages(walletId);
refetchPackagesData();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { calculateShade, convertToCurrency, replaceTopUpGeneralMessageVariables } = useUtils();
const primary_color = window.primary_color;
const primaryRgb = hexRgb(window.primary_color, { format: 'array' });
const primaryShade = '#' + rgbHex(calculateShade(primaryRgb.toString(), 0.2));
const selectedWalletPackage = ref();
const currentWallet = ref<Wallet>();
const { getWalletBalance } = useRfidGuest();
const { refetchWalletBalanceData, walletBalanceData } = getWalletBalance(encryptedRfidTag);
const { fiatCurrency } = useCurrencies();

const {
	availablePaymentCountries,
	checkIsLatestAmountGreaterThanMinimumAmount,
	enableTopUpSubmitButton,
	formValue,
	minimumAmountError,
	onAmountChange,
	paymentMethodCountry,
	paymentMethodCountryObject,
	selectTopUp,
	startTopUp,
	switchPayconiqMode,
	topUpType,
	defaultPaymentMethodCountry,
	countrySelectionValidated,
	getApplicablePaymentCountry,
} = useTopUp(tenantConfig, minimumTopUp, currentWallet);
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();
const sharedStore = useSharedStore();
const { getWallets } = useWallet();
const { wallets } = getWallets();

const { guestUserProfile, userMutate } = searchUser();

watch(
	[guestUserProfile],
	() => {
		if (loginToken && guestUserProfile.value) {
			currentWallet.value = guestUserProfile.value?.wallets.find((wallet) => wallet.id === +walletId);
		}
	},
	{ immediate: true },
);

onMounted(async () => {
	userMutate({ login_token: loginToken });
	// Reset state
	sharedStore.shouldUsePayconiq = true;

	if (tenantConfig && tenantConfig.value && tenantConfig.value.wallet_enable_packages) {
		topUpType.value = TopUp.TopUpTypePackages;
	} else {
		topUpType.value = TopUp.TopUpTypeFreeAmount;
	}

	if (encryptedRfidTag) {
		await refetchWalletBalanceData();
		currentWallet.value = walletBalanceData.value?.wallets.find((wallet) => wallet.id === +walletId);
	} else if (loginToken) {
		// use mutation to get user profile
	} else {
		if (wallets.value) {
			currentWallet.value = wallets.value.find((w) => {
				if (w.id.toString() === walletId) {
					return w;
				}
			});
		}
	}
});

// selectPackage
const selectPackage = (walletPackage: WalletPackage) => {
	if (selectedWalletPackage.value === undefined || selectedWalletPackage.value !== walletPackage) {
		// Prepare items
		const walletItems: any = walletPackage.contents.map((item) => {
			return {
				description: `${item.currency.prefix} ${item.amount} ${item.currency.name}`,
				itemPrice: item.amount * +item.currency.exchange_rate,
				quantity: 1,
				hideQuantiy: true,
				hidePrice: true,
			};
		});

		// Calculate fee
		let totalPriceString = '';
		let totalPriceNumber = 0;

		if (walletPackage.clientx_price_incl_fee > +walletPackage.clientx_price) {
			totalPriceString = `€${walletPackage.clientx_price_incl_fee.toFixed(2)}`;
			totalPriceNumber = walletPackage.clientx_price_incl_fee;
			walletItems.push({
				description: t('transaction.administrative_fee'),
				currency: '€',
				itemPrice: walletPackage.clientx_price_incl_fee - +walletPackage.clientx_price,
				quantity: 1,
				hideQuantiy: true,
				hidePrice: false,
			});
		} else {
			totalPriceString = `€${(+walletPackage.clientx_price).toFixed(2)}`;
			totalPriceNumber = +walletPackage.clientx_price;
		}

		// Prepare transaction
		const transaction: Transaction = {
			additionalInfo: {
				isGuestTopUp: encryptedRfidTag || loginToken ? true : false,
				encryptedRfidTag,
				redirectPath: encryptedRfidTag ? `/wallet/wallet-handler/${encryptedRfidTag}` : '/dashboard',
			},
			items: [...walletItems],
			totalDescription: `${t('transaction.total')}`,
			totalPriceString: totalPriceString,
			totalPriceNumber: totalPriceNumber,
			totalPriceWithoutFee: walletPackage.clientx_price,
			type: TransactionType.TOP_UP,
			subtype: TransactionSubtype.PACKAGE,
		};

		setItemsAndTotal(transaction);
		selectedWalletPackage.value = walletPackage;
	} else {
		selectedWalletPackage.value = undefined;
	}
};

// addTopUpPackage
const addTopUpPackage = () => {
	const paymentMethodCountryToSend = getApplicablePaymentCountry();
	const isPayconiqTopUp = tenantConfig.value!.viva_wallet_payconiq_preferred_mode && sharedStore.shouldUsePayconiq;

	selectTopUp(
		selectedWalletPackage.value.id,
		paymentMethodCountryToSend,
		isPayconiqTopUp,
		encryptedRfidTag,
		loginToken,
	);
};

// addTopUp
const addTopUp = () => {
	// Update value if custom valuta is enabled
	const amount = Number(formValue.value);
	const paymentMethodCountryToSend = getApplicablePaymentCountry();

	// Prepare transaction
	const transaction: Transaction = {
		additionalInfo: {
			isGuestTopUp: encryptedRfidTag || loginToken ? true : false,
			encryptedRfidTag,
			redirectPath: encryptedRfidTag ? `/wallet/wallet-handler/${encryptedRfidTag}` : '/dashboard',
		},
		items: [
			{
				description: `${currentWallet.value!.currency.prefix} ${amount} ${currentWallet.value!.currency.name}`,
				itemPrice: amount * currentWallet.value!.currency.exchange_rate,

				quantity: 1,
			},
		],
		totalDescription: `1 ${t('transaction.items_total')}`,
		totalPriceString: `€ ${(amount * currentWallet.value!.currency.exchange_rate).toFixed(2)}`,
		totalPriceNumber: amount,
		totalPriceWithoutFee: amount,
		type: TransactionType.TOP_UP,
		walletId,
	};

	// isPayconiqTopUp
	const isPayconiqTopUp = tenantConfig.value!.viva_wallet_payconiq_preferred_mode && sharedStore.shouldUsePayconiq;
	setItemsAndTotal(transaction);
	startTopUp(
		paymentMethodCountryToSend,
		isPayconiqTopUp,
		encryptedRfidTag,
		walletId,
		currentWallet.value!.currency.id,
		loginToken,
	);
};

// Go back
const goBack = () => {
	if (backToDashboard === 'true') {
		router.push('/dashboard');
	} else {
		router.back();
	}
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _directive_dompurify_html = _resolveDirective("dompurify-html")!

  return (_openBlock(), _createBlock(_unref(IonContent), {
    style: _normalizeStyle(`background: linear-gradient(${_unref(primary_color)}, ${primaryShade});`),
    class: _normalizeClass([
			{
				'payconiq': _unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode && _unref(sharedStore).shouldUsePayconiq,
			},
		])
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(TopBar, { class: "max-w-[600px] px-[34px]" }, {
          left: _withCtx(() => [
            _createElementVNode("div", {
              class: "flex items-center justify-start cursor-pointer",
              onClick: goBack
            }, [
              _createVNode(_unref(IonIcon), {
                icon: _unref(chevronBackOutline),
                class: "text-white"
              }, null, 8, ["icon"])
            ])
          ]),
          center: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(_unref(t)('topup.title')), 1)
          ]),
          right: _withCtx(() => [
            _withDirectives(_createElementVNode("div", null, [
              _createElementVNode("div", null, [
                _createVNode(_unref(IonSelect), {
                  interface: "popover",
                  "aria-label": "select",
                  placeholder: _unref(defaultPaymentMethodCountry)?.flag,
                  "selected-text": _unref(paymentMethodCountryObject) ? _unref(paymentMethodCountryObject).flag : '',
                  modelValue: _unref(paymentMethodCountry),
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(paymentMethodCountry) ? (paymentMethodCountry).value = $event : null)),
                  value: _unref(paymentMethodCountry)
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(availablePaymentCountries), (country) => {
                      return (_openBlock(), _createBlock(_unref(IonSelectOption), {
                        value: country.value,
                        key: country.value
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(country.label), 1)
                        ]),
                        _: 2
                      }, 1032, ["value"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["placeholder", "selected-text", "modelValue", "value"])
              ])
            ], 512), [
              [_vShow, 
							_unref(availablePaymentCountries).length > 1 &&
							_unref(tenantConfig) &&
							_unref(tenantConfig)!.payment_countries.length > 0
						]
            ])
          ]),
          _: 1
        }),
        (_unref(tenantConfig))
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("div", _hoisted_5, [
                  (_unref(tenantConfig).wallet_enable_packages && _unref(walletPackages) && _unref(topUpType))
                    ? (_openBlock(), _createBlock(_unref(IonSegment), {
                        key: 0,
                        class: "h-[50px] mb-2 ion-no-margin",
                        value: _unref(topUpType),
                        mode: "ios"
                      }, {
                        default: _withCtx(() => [
                          (_unref(tenantConfig).wallet_enable_packages)
                            ? (_openBlock(), _createBlock(_unref(IonSegmentButton), {
                                key: 0,
                                class: "ion-no-margin",
                                value: _unref(TopUp).TopUpTypePackages,
                                onClick: _cache[1] || (_cache[1] = ($event: any) => (topUpType.value = _unref(TopUp).TopUpTypePackages))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(IonLabel), { class: "font-sourcesanspro-semibold text-base2" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(t)('top_up.packages')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["value"]))
                            : _createCommentVNode("", true),
                          (_unref(tenantConfig).wallet_enable_free_input)
                            ? (_openBlock(), _createBlock(_unref(IonSegmentButton), {
                                key: 1,
                                class: "ion-no-margin",
                                value: _unref(TopUp).TopUpTypeFreeAmount,
                                onClick: _cache[2] || (_cache[2] = ($event: any) => (topUpType.value = _unref(TopUp).TopUpTypeFreeAmount))
                              }, {
                                default: _withCtx(() => [
                                  _createVNode(_unref(IonLabel), { class: "font-sourcesanspro-semibold text-base2" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_unref(t)('top_up.free_amount')), 1)
                                    ]),
                                    _: 1
                                  })
                                ]),
                                _: 1
                              }, 8, ["value"]))
                            : _createCommentVNode("", true)
                        ]),
                        _: 1
                      }, 8, ["value"]))
                    : _createCommentVNode("", true),
                  (
								!_unref(countrySelectionValidated) &&
								_unref(availablePaymentCountries).length > 1 &&
								_unref(tenantConfig) &&
								_unref(tenantConfig)!.payment_countries.length > 0 &&
								!(_unref(tenantConfig)?.viva_wallet_payconiq_preferred_mode && _unref(sharedStore).shouldUsePayconiq)
							)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createElementVNode("span", _hoisted_7, _toDisplayString(_unref(t)('top_up.first_country_selection_title')), 1),
                        _createTextVNode(" " + _toDisplayString(_unref(t)('top_up.first_country_selection')), 1)
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(userProfile) && _unref(tenantConfig).top_up_general_message)
                    ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_8, null, 512)), [
                        [_directive_dompurify_html, 
								_unref(replaceTopUpGeneralMessageVariables)(_unref(tenantConfig).top_up_general_message, _unref(userProfile))
							]
                      ])
                    : _createCommentVNode("", true)
                ]),
                (!_unref(tenantConfig) || !_unref(minimumTopUp))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                      _createVNode(_unref(IonSpinner), { name: "dots" })
                    ]))
                  : _createCommentVNode("", true),
                (_unref(topUpType) === _unref(TopUp).TopUpTypeFreeAmount)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                      (_unref(tenantConfig).wallet_enable_free_input)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                            _createElementVNode("span", _hoisted_12, _toDisplayString(currentWallet.value?.currency.prefix), 1),
                            _createElementVNode("div", _hoisted_13, [
                              _createVNode(TextInput, {
                                "wrapper-class": "flex flex-wrap justify-left item-center border border-solid focus-within:background-white-15",
                                "input-class": "text-lg",
                                "input-mode": "decimal",
                                name: "amount",
                                placeholder: "0",
                                type: "number",
                                step: "any",
                                onInputChanged: _unref(onAmountChange),
                                "should-validate": false
                              }, null, 8, ["onInputChanged"])
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", _hoisted_14, [
                        (_unref(formValue) > 0)
                          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
                              (currentWallet.value)
                                ? (_openBlock(), _createElementBlock("span", _hoisted_16, _toDisplayString(currentWallet.value?.currency.prefix) + " " + _toDisplayString(_unref(formValue)) + " " + _toDisplayString(_unref(tenantConfig).custom_valuta.suffix) + " = " + _toDisplayString(_unref(fiatCurrency).prefix) + " " + _toDisplayString((_unref(formValue) * +currentWallet.value?.currency.exchange_rate! + +_unref(tenantConfig).top_up_cost).toFixed(2)), 1))
                                : _createCommentVNode("", true)
                            ]))
                          : _createCommentVNode("", true),
                        (_unref(minimumAmountError))
                          ? (_openBlock(), _createElementBlock("p", _hoisted_17, [
                              _createTextVNode(_toDisplayString(_unref(t)('balance.errorMinimumAmount')) + " ", 1),
                              _createElementVNode("span", null, _toDisplayString(_unref(fiatCurrency).prefix) + " " + _toDisplayString(_unref(minimumTopUp)?.amount), 1)
                            ]))
                          : _createCommentVNode("", true)
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_unref(minimumTopUp) && _unref(topUpType) === _unref(TopUp).TopUpTypePackages)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
                      (
								_unref(previousTopUp) &&
								_unref(previousTopUp).amount &&
								_unref(minimumAmountError) &&
								_unref(checkIsLatestAmountGreaterThanMinimumAmount)(_unref(minimumTopUp).amount, _unref(previousTopUp).amount)
							)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_19, [
                            _createElementVNode("div", _hoisted_20, [
                              _createElementVNode("span", _hoisted_21, _toDisplayString(_unref(t)('balance.minimum_amount')), 1)
                            ]),
                            _createElementVNode("div", _hoisted_22, [
                              _createVNode(_unref(IonButton), { class: "rounded-full bg-white/10 small font-sourcesanspro-regular text-base2 text-white" }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, _toDisplayString(currentWallet.value?.currency.prefix) + _toDisplayString(_unref(convertToCurrency)(_unref(minimumTopUp).amount)), 1)
                                ]),
                                _: 1
                              })
                            ])
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(walletPackages))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(walletPackages), (walletPackage, index) => {
                              return (_openBlock(), _createBlock(ItemWithContentStandalone, {
                                key: index,
                                "has-description": !!walletPackage.description,
                                icon: require('@/statics/svg/icons/box.svg'),
                                image: walletPackage.image ? walletPackage.image : undefined,
                                title: walletPackage.name,
                                price: _unref(fiatCurrency).prefix + walletPackage.clientx_price_incl_fee,
                                "is-selected": selectedWalletPackage.value && selectedWalletPackage.value.id == walletPackage.id,
                                onOnSelectItem: ($event: any) => (selectPackage(walletPackage))
                              }, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(walletPackage.description), 1)
                                ]),
                                _: 2
                              }, 1032, ["has-description", "icon", "image", "title", "price", "is-selected", "onOnSelectItem"]))
                            }), 128))
                          ]))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_24, [
                _createElementVNode("div", _hoisted_25, [
                  (!_unref(encryptedRfidTag) && !_unref(loginToken))
                    ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
                        _createElementVNode("div", _hoisted_27, [
                          _createVNode(_component_router_link, { to: "/top-up/redeem" }, {
                            default: _withCtx(() => [
                              _createElementVNode("span", _hoisted_28, _toDisplayString(_unref(t)('top_up.redeem_voucher.redeem')), 1)
                            ]),
                            _: 1
                          })
                        ])
                      ]))
                    : _createCommentVNode("", true),
                  (_unref(tenantConfig).viva_wallet_payconiq_preferred_mode && _unref(sharedStore).shouldUsePayconiq)
                    ? (_openBlock(), _createBlock(_unref(IonButton), {
                        key: 1,
                        class: "flex items-center justify-center mb-2 w-full font-sourcesanspro-regular text-white text-sm2 cursor-pointer",
                        shape: "round",
                        fill: "outline",
                        onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(switchPayconiqMode)()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_unref(t)('balance.different_payment_options')), 1)
                        ]),
                        _: 1
                      }))
                    : _createCommentVNode("", true),
                  _createVNode(_unref(IonButton), {
                    class: "w-full max-w-none hover:bg-white hover:rounded-full",
                    expand: "full",
                    size: "large",
                    shape: "round",
                    disabled: 
								(_unref(topUpType) === _unref(TopUp).TopUpTypeFreeAmount && !_unref(enableTopUpSubmitButton)) ||
								(_unref(topUpType) === _unref(TopUp).TopUpTypePackages && !selectedWalletPackage.value)
							,
                    onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(topUpType) === _unref(TopUp).TopUpTypeFreeAmount ? addTopUp() : addTopUpPackage()))
                  }, {
                    default: _withCtx(() => [
                      (
									!_unref(tenantConfig).viva_wallet_payconiq_preferred_mode || !_unref(sharedStore).shouldUsePayconiq
								)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_29, [
                            _createElementVNode("span", null, _toDisplayString(_unref(t)('general.continue')), 1),
                            _createVNode(_unref(IonIcon), {
                              icon: _unref(arrowForwardOutline),
                              class: "text-dark-blue mr-2"
                            }, null, 8, ["icon"])
                          ]))
                        : _createCommentVNode("", true),
                      (_unref(tenantConfig).viva_wallet_payconiq_preferred_mode && _unref(sharedStore).shouldUsePayconiq)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_30, [
                            _createElementVNode("span", null, _toDisplayString(_unref(t)('balance.pay_with')), 1),
                            _createElementVNode("img", {
                              class: "w-[94px] h-[31px]",
                              src: require('@/statics/png/payconiq/payconiq-logo-dark.png')
                            }, null, 8, _hoisted_31)
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["style", "class"]))
}
}

})