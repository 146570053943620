import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "absolute flex flex-col justify-between top-0 left-0 w-full h-full bg-white z-10"
}
const _hoisted_2 = { class: "flex relative items-center justify-center" }
const _hoisted_3 = { class: "flex items-center justify-center mb-4" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-base" }
const _hoisted_5 = {
  key: 1,
  class: "flex flex-col items-center justify-center p-4 backdrop-blur bg-white/10 rounded-md font-sourcesanspro-regular"
}
const _hoisted_6 = { class: "mb-2" }
const _hoisted_7 = { class: "text-m2" }
const _hoisted_8 = { class: "text-center text-sm2 text-white/60 mb-2" }

import { onMounted, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { IonButton, IonIcon } from '@ionic/vue';
import { Capacitor } from '@capacitor/core';
import { cameraOutline, cameraReverseOutline, closeOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import TopBar from '@/shared/components/TopBar.vue';
import useCamera from '@/shared/composables/useCamera';
import useNativeSettings from '@/shared/composables/useNativeSettings';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'ScanQR',
  props: {
	extractParam: { type: String },
	startCamera: { type: Boolean, default: false },
	permissionTitle: { type: String, required: true },
	permissionDescription: { type: String, required: false },
},
  emits: ['onScanQR'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const { t } = useI18n();
const route = useRoute();
const disableScanButton = ref(false);
const isReverseCameraPresent = ref(false);
const {
	cameraPermission,
	canvasElement,
	canClose,
	captureImage,
	checkPermission,
	fileinputElement,
	handleFile,
	reverseCamera,
	reverseCameraPresent,
	scanActive,
	scanResult,
	scanQr,
	stopScan,
	videoElement,
} = useCamera();

const { openAppSettings } = useNativeSettings();
const emit = __emit;

// onMountedHook
onMounted(async () => {
	// checkPermission
	cameraPermission.value = await checkPermission();

	// Start camera
	if (props.startCamera) {
		disableScanButton.value = false;
		await scanQr();
	}

	if (route.query.voucherID) {
		const voucherID = route.query.voucherID;
		emit('onScanQR', voucherID);
	}

	// Reverse camera enabled
	setTimeout(async () => {
		isReverseCameraPresent.value = await reverseCameraPresent();
	}, 500);
});

// Watch changes on scanResult
watch(scanResult, () => {
	if (props.extractParam) {
		const url = new URL(scanResult.value.replaceAll('/#', ''));
		const param = url.searchParams.get(props.extractParam);
		emit('onScanQR', param);
	} else {
		emit('onScanQR', scanResult.value);
	}
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", null, [
    (_unref(cameraPermission) === 'granted' && _unref(scanActive))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(TopBar, { class: "px-[34px]" }, {
            left: _withCtx(() => [
              (_unref(canClose))
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: "flex items-center justify-start cursor-pointer",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(stopScan)()))
                  }, [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(closeOutline),
                      class: "text-anykrowd-dark"
                    }, null, 8, ["icon"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }),
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("input", {
              ref_key: "fileinputElement",
              ref: fileinputElement,
              type: "file",
              accept: "image/*;capture=camera",
              hidden: "",
              onChange: _cache[1] || (_cache[1] = ($event: any) => (_unref(handleFile)(($event.target! as HTMLInputElement).files!)))
            }, null, 544),
            _createElementVNode("video", {
              ref_key: "videoElement",
              ref: videoElement,
              class: "w-full h-full z-10 max-w-[600px] player",
              width: "100",
              height: "100",
              controls: false,
              playsinline: "",
              autoplay: ""
            }, null, 512),
            _withDirectives(_createElementVNode("canvas", {
              ref_key: "canvasElement",
              ref: canvasElement
            }, null, 512), [
              [_vShow, false]
            ])
          ]),
          _createElementVNode("div", null, [
            (_unref(Capacitor).isNativePlatform() && isReverseCameraPresent.value)
              ? (_openBlock(), _createBlock(_unref(IonButton), {
                  key: 0,
                  class: "flex z-10 h-[64px] no-border bg-white",
                  fill: "outline",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_unref(reverseCamera)()))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_unref(IonIcon), {
                      icon: _unref(cameraReverseOutline),
                      class: "text-anykrowd-dark",
                      size: "large"
                    }, null, 8, ["icon"])
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_unref(IonButton), {
              class: "h-[64px] no-border bg-white ion-no-margin ion-no-padding",
              expand: "full",
              fill: "outline",
              onClick: _cache[3] || (_cache[3] = ($event: any) => (_unref(captureImage)()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('scan_qr.capture_image')), 1)
              ]),
              _: 1
            }),
            _createVNode(_unref(IonButton), {
              class: "h-[64px] no-border bg-anykrowd-dark-purple ion-no-margin ion-no-padding",
              expand: "full",
              fill: "outline",
              onClick: _cache[4] || (_cache[4] = ($event: any) => (_unref(stopScan)())),
              color: "primary"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_unref(t)('scan_qr.stop_scan')), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_unref(IonButton), {
        disabled: !_unref(cameraPermission) || _unref(cameraPermission) === 'denied' || disableScanButton.value,
        fill: "outline",
        shape: "round",
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_unref(scanQr)())),
        color: "primary"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_4, _toDisplayString(_unref(t)('scan_qr.use_camera')), 1),
          _createVNode(_unref(IonIcon), {
            class: "cursor-pointer",
            icon: _unref(cameraOutline),
            slot: "end"
          }, null, 8, ["icon"])
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    (_unref(cameraPermission) === 'denied')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("span", _hoisted_7, _toDisplayString(props.permissionTitle), 1)
          ]),
          _createElementVNode("p", _hoisted_8, _toDisplayString(props.permissionDescription), 1),
          (_unref(Capacitor).isNativePlatform())
            ? (_openBlock(), _createBlock(_unref(IonButton), {
                key: 0,
                onClick: _cache[6] || (_cache[6] = ($event: any) => (_unref(openAppSettings)()))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_unref(t)('scan_qr.open_settings')), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ]))
}
}

})