<script setup lang="ts">
import { ref, watch } from 'vue';
import { IonModal } from '@ionic/vue';

// Props
const props = defineProps({
	isOpen: { type: Boolean, required: true, default: false },
});

// Init
const emit = defineEmits(['didDismiss']);
const isOpenRef = ref(props.isOpen);

// Watch changes on the prop
watch(
	() => props.isOpen,
	(newValue) => {
		isOpenRef.value = newValue;
	},
);
</script>

<template>
	<ion-modal :is-open="isOpenRef" class="h-full" @didDismiss="emit('didDismiss')">
		<slot />
	</ion-modal>
</template>

<style lang="scss" scoped>
ion-modal {
	--height: 100%;
}
</style>
