<script setup lang="ts">
import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { closeOutline, personAddOutline, ticketOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { CartCategoriesEnum } from '@/enums/values.enum';
import { EventComboTicketType } from '@/shared/interfaces/eventComboTicketType';
import { EventTicketType } from '@/shared/interfaces/eventTicketType';
import { useCart } from '@/modules/shop/composables/useCart';
import ItemAdder from '@/shared/components/ui/ItemAdder.vue';
import useEvents from '@/modules/events/composables/useEvents';
import useToast from '@/shared/composables/useToast';

// Init
const { t } = useI18n();
const route = useRoute();
const eventId = +route.params.id;
const { getEvent } = useEvents();
const { buyTicketLimits, decreaseQuantity, event, getEventTicketTypes, increaseQuantity } = getEvent(eventId);
const { eventComboTicketTypes, eventTicketTypes, isFetchedEventTicketTypes } = getEventTicketTypes(eventId);
const { getCartTotalItemsByCategory, getProductCountWithDuplicates } = useCart();
const { presentToast } = useToast();

// Handle decrease product
const onDecreaseProductQuantity = (item: EventTicketType | EventComboTicketType) => {
	decreaseQuantity(item);
};

// Handle increase product
const onIncreaseProductQuantity = async (productId: number, item: EventTicketType | EventComboTicketType) => {
	if (getCartTotalItemsByCategory(CartCategoriesEnum.ticket) + 1 > buyTicketLimits.value!.ticketLimit.find((ticketType) => ticketType.type === item.name)!.limit) {
		await presentToast('top', t('ticket.buy.event_limit', { eventName: event.value?.name }), 5000, 'danger');
	}
	if (getCartTotalItemsByCategory(CartCategoriesEnum.ticket) >= buyTicketLimits.value!.eventLimit) {
		await presentToast('top', t('ticket.buy.event_limit', { eventName: event.value?.name }), 5000, 'danger');
	}

	if (getCartTotalItemsByCategory(CartCategoriesEnum.ticket) < buyTicketLimits.value!.ticketLimit.find((ticketType) => ticketType.type === item.name)!.limit) {
		await increaseQuantity(productId, item);
	}
};
</script>

<template>
	<ion-content>
		<div class="flex flex-col justify-between px-[34px]">
			<div class="flex flex-col mt-12 mb-10 items-center">
				<div class="flex items-center justify-center mb-4">
					<ion-button
						class="rounded ion-no-padding ion-no-margin w-[60px] h-[60px] hover:rounded-full hover:bg-white/20"
						:routerLink="`/events/${eventId}`">
						<ion-icon class="text-[38px] text-white cursor-pointer" :icon="closeOutline"></ion-icon>
					</ion-button>
				</div>
				<h1 class="font-sourcesanspro-semibold text-lg2">{{ event?.name }}</h1>
			</div>

			<div v-if="!isFetchedEventTicketTypes" class="flex items-center justify-center mb-4">
				<ion-spinner name="dots"></ion-spinner>
			</div>
			<div
				v-if="
					event &&
					buyTicketLimits &&
					isFetchedEventTicketTypes &&
					eventTicketTypes &&
					eventTicketTypes.ticket_types.length
				">
				<div class="mb-8">
					<div class="flex items-center mb-4">
						<ion-icon class="mr-2 text-[20px] text-white cursor-pointer" :icon="ticketOutline"></ion-icon>
						<h2 class="font-sourcesanspro-semibold text-lg inline">{{ t('ticket.buy.tickets') }}</h2>
					</div>
					<div>
						<ItemAdder
							v-for="(eventTicketType, idx) in eventTicketTypes.ticket_types"
							@onDecreaseProductQuantity="
								(product) => {
									onDecreaseProductQuantity(eventTicketType);
								}
							"
							@onIncreaseProductQuantity="
								(product) => {
									onIncreaseProductQuantity(product.id, eventTicketType);
								}
							"
							add-more-error="ticket.buy.ticket_type_limit"
							:id="eventTicketType.id"
							:add-more-error-detail="eventTicketType.name"
							:description="eventTicketType.description"
							:expandable="eventTicketType.description ? true : false"
							:key="idx"
							:productId="+eventTicketType.id!"
							:product="eventTicketType"
							class="mb-4"
							:title="eventTicketType.name"
							:discount="eventTicketType.discount"
							:price="+eventTicketType.price"
							:priceWithDiscount="+eventTicketType.discountedPrice()!"
							:currency="eventTicketType.currency"
							:quantity="getProductCountWithDuplicates(eventTicketType)"
							:can-add-more="
								getCartTotalItemsByCategory(CartCategoriesEnum.ticket) < buyTicketLimits.eventLimit &&
								(buyTicketLimits.ticketLimit.find(
									(ticketType) => ticketType.type === eventTicketType.name,
								)!.limit === -1
									? true
									: getProductCountWithDuplicates(eventTicketType) + 1 <=
										buyTicketLimits.ticketLimit.find(
											(ticketType) => ticketType.type === eventTicketType.name,
										)!.limit)
							" />
					</div>
				</div>

				<div v-if="isFetchedEventTicketTypes && eventComboTicketTypes?.length" class="mb-4">
					<div class="flex items-center mb-4">
						<ion-icon class="mr-2 text-[20px] text-white cursor-pointer" :icon="ticketOutline"></ion-icon>
						<h2 class="font-sourcesanspro-semibold text-lg inline">
							{{ t('ticket.buy.tickets_combination') }}
						</h2>
					</div>
					<div>
						<ItemAdder
							v-for="(eventTicketCombo, idx) in eventComboTicketTypes"
							@onDecreaseProductQuantity="
								(productId) => {
									onDecreaseProductQuantity(eventTicketCombo);
								}
							"
							@onIncreaseProductQuantity="
								(product) => {
									onIncreaseProductQuantity(product.id, eventTicketCombo);
								}
							"
							:description="eventTicketCombo.description"
							:key="idx"
							:productId="+eventTicketCombo.id!"
							:product="eventTicketCombo"
							class="mb-4"
							:can-add-more="true"
							:title="eventTicketCombo.name"
							:discount="eventTicketCombo.discount"
							:price="+eventTicketCombo.price"
							:priceWithDiscount="+eventTicketCombo.discountedPrice()!"
							:currency="eventTicketCombo.currency"
							:quantity="getProductCountWithDuplicates(eventTicketCombo)"
							:sold-out="false" />
					</div>
				</div>
			</div>

			<div class="mb-8">
				<ion-button
					v-if="isFetchedEventTicketTypes"
					:disabled="getCartTotalItemsByCategory(CartCategoriesEnum.ticket) === 0"
					class="w-full hover:rounded-full hover:bg-white"
					expand="full"
					size="large"
					shape="round"
					:routerLink="`/events/${eventId}/tickets/addmembers`">
					<div class="flex w-full items-center justify-between">
						<span class="text-m2">{{ t('events.buy_more.add_members') }}</span>
						<span class="text-m2">{{ t('events.buy_more.continue') }}</span>
					</div>
				</ion-button>
			</div>
		</div>
	</ion-content>
</template>

<style lang="scss" scoped>
ion-searchbar {
	--box-shadow: none;
}
</style>
