import { Wallet } from '@/shared/interfaces/balance';
import { DateTime } from 'luxon';

export interface UserLoginData {
	email: string;
	device_name: string;
	password: string;
	rememberMe: boolean;
	phone_number?: string;
}
export type SocialProvider = 'google' | 'facebook' | 'apple';

export interface UserToken {
	token: string;
	approval_required?: boolean;
}

// Generated by https://quicktype.io
export class UserProfile {
	[key: string]: any;
	id: number;
	uuid: string;
	admin: boolean;
	approved: boolean;
	company: string;
	is_contributor: number;
	serviceuser: number;
	firstname: string;
	lastname: string;
	nickname: string;
	function_title: string;
	gender: string;
	city: string;
	postal_code: string;
	country: string;
	address_street: string;
	address_number: string;
	date_of_birth: string;
	email: string;
	has_external_id: boolean;
	invoice_company: string;
	invoice_street: string;
	invoice_city: string;
	invoice_postal_code: string;
	invoice_vat_number: string;
	remember_token: string;
	profile_image: string | FormData | File;
	approval_image?: string | File;
	notification_in_app: number;
	notification_sms: number;
	notification_email: number;
	notification_cellphone_number: string | number;
	notification_email_address: string;
	facebook_id: string;
	google_id: string;
	social_registered: boolean;
	staff_registered: boolean;
	overspending: boolean;
	config_interests: boolean;
	terms_and_conditions_accepted_at: string;
	privacy_policy_accepted_at: string;
	blocked_at: string;
	block_from: string;
	block_until: string;
	email_verified_at: string;
	created_at: string;
	updated_at: string;
	deleted_at: string;
	profile_completed: boolean;
	profile_image_url: string;
	full_name: string;
	is_master: boolean;
	'2fa_enabled': boolean;
	images: any[];
	tags: Tag[];
	wallets: Wallet[];
  wallet_layout_image: boolean | undefined;
  wallet_layout_text_color: string | undefined;

	constructor(existingUser?: UserProfile) {
		this.id = existingUser?.id || 0;
		this.uuid = existingUser?.uuid || '';
		this.approved = false;
		this.company = existingUser?.company || '';
		this.serviceuser = 0;
		this.facebook_id = '';
		this.google_id = '';
		this.staff_registered = false;
		this.terms_and_conditions_accepted_at = '';
		this.privacy_policy_accepted_at = '';
		this.blocked_at = '';
		this.block_from = '';
		this.block_until = '';
		this.email_verified_at = '';
		this.has_external_id = false;
		this.deleted_at = '';
		this.firstname = existingUser?.firstname || '';
		this.remember_token = '';
		this.approval_image = '';
		this.notification_email_address = '';
		this.lastname = existingUser?.lastname || '';

		this['2fa_enabled'] = existingUser && existingUser['2fa_enabled'] ? existingUser['2fa_enabled'] : false;
		this.admin = existingUser?.admin || false;
		this.city = existingUser?.city || '';
		this.address_street = existingUser?.address_street || '';
		this.address_number = existingUser?.address_number || '';
		this.created_at = existingUser?.created_at || '';
		this.country = existingUser?.country || '';
		this.date_of_birth =
			existingUser?.date_of_birth && DateTime.fromFormat(existingUser.date_of_birth, 'yyyy-MM-dd').isValid
				? existingUser.date_of_birth
				: '';
		this.email = existingUser?.email || '';
		this.function_title = existingUser?.function_title || '';
		this.gender = existingUser?.gender || '';
		this.nickname = existingUser?.nickname || '';
		this.password = existingUser?.password || '';
		this.postal_code = existingUser?.postal_code || '';
		this.updated_at = existingUser?.updated_at || '';
		this.profile_image = existingUser?.profile_image || '';
		this.profile_image_url = existingUser?.profile_image_url || '';
		this.profile_completed = existingUser?.profile_completed || false;
		this.tags = existingUser?.tags || [];
		this.images = existingUser?.images || [];
		this.full_name = existingUser?.full_name || '';
		this.has_invite = existingUser?.has_invite || false;
		this.is_contributor = existingUser?.is_contributor || 0;
		this.is_master = existingUser?.is_master || false;
		this.notification_in_app = existingUser?.notification_in_app || 0;
		this.notification_sms = existingUser?.notification_sms || 0;
		this.notification_email = existingUser?.notification_email || 0;
		this.notification_cellphone_number = existingUser?.notification_cellphone_number || '';
		this.overspending = existingUser?.overspending || false;
		this.config_interests = existingUser?.config_interests || false;
		this.social_registered = existingUser?.social_registered || false;

		this.invoice_company = existingUser?.invoice_company || '';
		this.invoice_street = existingUser?.invoice_street || '';
		this.invoice_city = existingUser?.invoice_city || '';
		this.invoice_postal_code = existingUser?.invoice_postal_code || '';
		this.invoice_vat_number = existingUser?.invoice_vat_number || '';
		this.wallets = existingUser?.wallets || [];
    this.wallet_layout_image = existingUser?.wallet_layout_image || undefined;
    this.wallet_layout_text_color = existingUser?.wallet_layout_image && existingUser?.wallet_layout_text_color ? existingUser?.wallet_layout_text_color : undefined;
	}

	get isGuestUser(): boolean {
		return !!this.id;
	}
}

export interface Tag {
	id: number;
	parent_id: number | null;
	name: string;
	invisible: boolean;
	technical: boolean;
	created_at: string;
	updated_at: string;
	pivot?: Pivot;
	parent?: Tag | null;
}

export interface Pivot {
	user_id: number;
	tag_id: number;
}

export interface UserSetPassword {
	token: string | undefined;
	uuid: string | undefined;
	password: string;
	password_confirmation: string;
	device_name: string;
}

export class UserProfileUpdate {
	firstname: string;
	lastname: string;
	nickname: string;
	gender: string;
	date_of_birth: string | Date;
	country: string;
	city: string;
	address_street: string;
	address_number: string;
	postal_code: string;
	profile_image: string | FormData | File;
	profile_image_url: string;
	email: string;
	approval_image?: string | File;
	notification_cellphone_number?: string | number;

	constructor() {
		this.firstname = '';
		this.lastname = '';
		this.nickname = '';
		this.gender = '';
		this.date_of_birth = '';
		this.country = '';
		this.city = '';
		this.address_street = '';
		this.address_number = '';
		this.postal_code = '';
		this.profile_image = '';
		this.profile_image_url = '';
		this.email = '';
		this.notification_cellphone_number = '';
	}
}
