<script lang="ts" setup>
import { defineExpose, onMounted, onBeforeUnmount, ref, watch, watchEffect } from 'vue';
import router from '@/router';
import { IonButton, IonContent, IonIcon, IonItem, IonList, IonPopover, IonSpinner } from '@ionic/vue';
import { chevronDownOutline, chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { DateTime } from 'luxon';
import { captureMessage } from '@/sentry';
import { useI18n } from 'vue-i18n';

import { useAuthStore } from '@/stores/auth/authStore';
import { Wallet } from '../interfaces/balance';
import useTenant from '@/shared/composables/useTenant';
import useToast from '@/shared/composables/useToast';
import useUtils from '@/shared/composables/useUtils';
import useWallet from '@/modules/wallet/composables/useWallet';
import useStorageService from '@/shared/composables/useStorageService';
import useUserProfile from '@/modules/profile/composables/useUserProfile';

const props = defineProps({
	user: { type: String, default: '' },
	walletId: { type: String, default: '' },
});

// Init
const { t } = useI18n();
const emit = defineEmits(['onWalletSet']);
const { presentToast } = useToast();
const authStore = useAuthStore();
const { getWallets, selectedWallet } = useWallet();
const { getTenantConfigAndStyles } = useTenant();
const { tenantConfig } = getTenantConfigAndStyles();
const { refetchWalletsData, wallets, walletUpdatedAt } = getWallets();
const { convertToCurrency, toRelativeTime } = useUtils();
const unsubscribe = ref();
const isOnline = ref(navigator.onLine);
const startTime = ref(new Date());
const showWarningMessage = ref(false);
const { get, set } = useStorageService();
const { getUserProfile } = useUserProfile();
const { userProfile } = getUserProfile();

// Expose the method to the parent component
defineExpose({
	refetchWalletsData,
});

if (authStore.isAuthenticated()) {
	refetchWalletsData();
}

const handleWalletChange = async () => {
	if (authStore.isAuthenticated() && wallets.value) {
		if (wallets.value) {
			if (wallets?.value?.length && wallets?.value?.length > 0) {
				selectedWallet.value = wallets.value?.find((wallet) => {
					return wallet.currency.default;
				});
				// if not default wallet, then select first
				if (props.walletId) {
					selectedWallet.value = wallets.value?.find((wallet) => {
						return wallet.id == +props.walletId;
					});
				} else if (!selectedWallet.value) {
					selectedWallet.value = wallets.value[0];
				}
			}
			await set('selectedWallet', selectedWallet.value);
			emit('onWalletSet', selectedWallet.value);
		}
	}
};

onMounted(async () => {
	const selectedWalletFromCache = await get('selectedWallet');
	if (selectedWalletFromCache) {
		selectedWallet.value = selectedWalletFromCache;
	}
	await handleWalletChange();
});

watch(wallets, async () => {
	if (wallets.value) {
		await handleWalletChange();
	}
});

const warningMessageInterval = setInterval(() => {
	const seconds = Math.round(DateTime.now().diff(DateTime.fromJSDate(startTime.value)).milliseconds / 1000);
	if (seconds > 5 && !selectedWallet.value) {
		showWarningMessage.value = true;
		clearInterval(warningMessageInterval);
	} else if (seconds > 5) {
		clearInterval(warningMessageInterval);
	} else {
		showWarningMessage.value = false;
	}
}, 1000);

onBeforeUnmount(() => {
	if (unsubscribe.value) {
		unsubscribe.value();
	}
});

const selectWallet = async (wallet: Wallet | undefined) => {
	if (!wallet) {
		captureMessage(`Attempted to select a wallet that does not exist`);
	}
	selectedWallet.value = wallet;
	await set('selectedWallet', wallet);
	emit('onWalletSet', wallet);
};

const getWalletAtIndex = (index: number) => {
	if (index >= wallets.value!.length) {
		index = 0;
	} else if (index < 0) {
		index = wallets.value!.length - 1;
	}
	return wallets.value?.[index];
};

const currentWalletIndex = () => {
	return wallets.value!.findIndex((wallet) => wallet.id === selectedWallet.value!.id);
};

const getPreviousWallet = () => {
	return getWalletAtIndex(currentWalletIndex() - 1);
};

const getNextWallet = () => {
	return getWalletAtIndex(currentWalletIndex() + 1);
};

const selectPreviousWallet = () => {
	selectWallet(getPreviousWallet());
};

const selectNextWallet = () => {
	selectWallet(getNextWallet());
};

const hideOfflineMessage = () => {
	isOnline.value = true;
};

const showOfflineMessage = () => {
	isOnline.value = false;
};

window.addEventListener('offline', (e) => {
	showOfflineMessage();
});

window.addEventListener('online', (e) => {
	hideOfflineMessage();
});

const goTo = async (link: string) => {
	// If the user is not authenticated, we will first ask him to sign in
	if (!authStore.isAuthenticated()) {
		await presentToast('top', t('general.please_login_first'), 5000, 'danger');
		await router.push('/auth/signuphome');
	} else {
		await router.push(link);
	}
};

const walletLayoutImage = ref(userProfile?.value?.wallet_layout_image ?? null);
const walletLayoutTextColor = ref(userProfile?.value?.wallet_layout_text_color ?? null);

watch(
	() => userProfile?.value,
	(newValue) => {
		walletLayoutImage.value = newValue?.wallet_layout_image ?? null;
		walletLayoutTextColor.value = newValue?.wallet_layout_text_color ?? null;
	},
	{ deep: true }
);
</script>

<template>
	<div
		v-if="!tenantConfig?.hide_wallet"
		:class="[
			{
				'mb-2': !tenantConfig?.viva_wallet_payconiq_preferred_mode,
				'mb-8': tenantConfig?.viva_wallet_payconiq_preferred_mode,
			},
		]">
		<div class="flex">
			<div
				v-if="isOnline && authStore.isAuthenticated()"
				class="flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer">
				<ion-icon
					v-if="wallets && wallets?.length > 1"
					:icon="chevronBackOutline"
					class="text-white active:bg-white/30 active:rounded-full active:p-1"
					@click="selectPreviousWallet()"></ion-icon>
			</div>
			<div
				:class="[
					{
						'bg-white-image': !tenantConfig?.viva_wallet_payconiq_preferred_mode,
						'payconiq': tenantConfig?.viva_wallet_payconiq_preferred_mode,
						'text-white': tenantConfig?.viva_wallet_payconiq_preferred_mode,
						'font-color-dark': !tenantConfig?.viva_wallet_payconiq_preferred_mode,
						'mx-[34px]': !isOnline || !authStore.isAuthenticated(),
					},
				]"
				class="flex justify-between rounded-md p-4 w-full"
        :style="{
          'background-image': (!tenantConfig?.viva_wallet_payconiq_preferred_mode && walletLayoutImage) ? `url(${walletLayoutImage})` : undefined,
          'background-size': 'cover',
          'color': (!tenantConfig?.viva_wallet_payconiq_preferred_mode && walletLayoutImage && walletLayoutTextColor) ? walletLayoutTextColor +' !important' : undefined,
        }">
				<div
					class="flex flex-col justify-between w-full"
					:class="[
						{
							'px-4': !authStore.isAuthenticated(),
						},
					]">
					<div
						v-if="isOnline && authStore.isAuthenticated() && !selectedWallet"
						class="flex w-full items-center justify-center">
						<ion-spinner name="dots" 
            :class="[
                {
                'text-anykrowd-dark': !walletLayoutImage,
              }]"
              :style="{
                'color': !tenantConfig?.viva_wallet_payconiq_preferred_mode && walletLayoutImage && walletLayoutTextColor ? walletLayoutTextColor +' !important' : undefined,
              }"></ion-spinner>
					</div>
					<div v-if="authStore.isAuthenticated() && showWarningMessage && !selectedWallet" class="text-base">
						{{ t('general.connection.offline_long_message') }}
					</div>

					<div
						v-if="authStore.isAuthenticated()"
						class="flex flex-row justify-between"
						:class="{ 'mb-6': !selectedWallet?.currency?.description }">
						<div v-if="selectedWallet" class="flex flex-col">
							<div class="font-sourcesanspro-semibold text-sm"
              :class="[
                {
                'text-anykrowd-dark/50': !walletLayoutImage,
              }]">
								{{ t('components.wallet_box.your') }} {{ selectedWallet.currency.name }}
								{{ t('components.wallet_box.balance') }}
							</div>

							<span class="font-sourcesanspro-black text-lg3">
								{{ selectedWallet.currency.prefix }}
								{{ convertToCurrency(+selectedWallet.current_balance) }}
							</span>
							<!-- <span class="font-sourcesanspro-regular text-lg"> + 2</span> -->
						</div>
						<div class="flex justify-between mb-2">
							<div></div>
							<div v-if="selectedWallet">
								<div
									id="popover-button"
									class="flex items-center justify-center rounded-lg font-sourcesanspro-bold text-md cursor-pointer"
                  :class="[
                    {
                      'text-anykrowd-dark': !walletLayoutImage,
                    }]">
									<div class="mr-2">{{ selectedWallet.currency.prefix }}</div>
									<div class="mr-2">{{ selectedWallet?.currency.name }}</div>
									<ion-icon
										v-if="wallets && wallets?.length > 1"
										:icon="chevronDownOutline"
										class="text-md"
                    :class="[
                    {
                      'text-anykrowd-dark': !walletLayoutImage,
                    }]"
                     :style="{
                      'color': !tenantConfig?.viva_wallet_payconiq_preferred_mode && walletLayoutImage && walletLayoutTextColor ? walletLayoutTextColor +' !important' : undefined,
                    }"></ion-icon>
								</div>
								<ion-popover trigger="popover-button" :dismiss-on-select="true">
									<ion-content>
										<ion-list>
											<ion-item
												v-for="wallet in wallets"
												class="flex items-center justify-center ion-no-padding ion-no-margin"
												:key="wallet.id"
												:button="true"
												@click="selectWallet(wallet)"
												:detail="false">
												<div class="flex flex-col px-3 py-2 font-sourcesanspro-regular">
													<div class="mb-1">
														<span>
															{{ wallet.currency.prefix }}
															{{ wallet.current_balance }}
															{{ wallet.currency.name }}
														</span>
													</div>
													<!-- <span class="text-sm text-white/60">+2 BONUS COOKIES</span> -->
												</div>
											</ion-item>
										</ion-list>
									</ion-content>
								</ion-popover>
							</div>
						</div>
					</div>
					<div
						v-if="authStore.isAuthenticated() && selectedWallet && selectedWallet.currency?.description"
						class="flex flex-col font-sourcesanspro-semibold text-sm mb-6 mt-4"
            :class="[
                {
                'text-anykrowd-dark/50': !walletLayoutImage,
              }]">
						{{ selectedWallet.currency?.description }}
					</div>

					<div class="flex items-center justify-between">
						<ion-button
							v-if="
								isOnline &&
								authStore.isAuthenticated() &&
								selectedWallet &&
								selectedWallet.currency.topupable
							"
							:class="[
								{
									'black': !tenantConfig?.viva_wallet_payconiq_preferred_mode,
								},
							]"
							:shape="!tenantConfig?.viva_wallet_payconiq_preferred_mode ? 'round' : undefined"
							class="w-full h-[40px] font-sourcesanspro-semibold text-sm2 ion-no-margin"
							@click="goTo(`/top-up/${selectedWallet!.id}`)">
							<img
								v-if="tenantConfig?.viva_wallet_payconiq_preferred_mode"
								:src="require('@/statics/png/payconiq/payconiq-horizontal.png')"
								class="mr-1" />
							{{
								!tenantConfig?.viva_wallet_payconiq_preferred_mode
									? t('wallet.add')
									: `${t('wallet.add')} ${selectedWallet.currency.name} ${t('wallet.pay_with_payconiq')}`
							}}
						</ion-button>
					</div>
				</div>
			</div>
			<div
				v-if="isOnline && authStore.isAuthenticated()"
				class="flex w-[34px] min-w-[34px] items-center justify-center cursor-pointer">
				<ion-icon
					v-if="wallets && wallets?.length > 1"
					:icon="chevronForwardOutline"
					class="text-white active:bg-white/30 active:rounded-full active:p-1"
					@click="selectNextWallet()"></ion-icon>
			</div>
		</div>

		<div
			v-if="isOnline && walletUpdatedAt"
			class="flex px-[34px] mb-3 mt-[2px] justify-end font-sourcesanspro-regular text-xs">
			{{ t('wallet.updated_at') }}
			{{ toRelativeTime(t, walletUpdatedAt) }}
		</div>

		<div
			v-if="isOnline && tenantConfig?.viva_wallet_payconiq_preferred_mode"
			class="flex px-[34px] mx-auto items-center justify-between w-full">
			<span class="font-sourcesanspro-semibold drop-shadow-customtext-xs">Powered by payconiq</span>
			<img
				:src="require('@/statics/svg/payconiq/payconiq-horizontal-neg.svg')"
				class="w-[74px] h-[13px]drop-shadow-custom" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
.bg-white-image {
	background: url('@/statics/png/wallet/wallet-bg.png') no-repeat;
	background-size: cover;
}

.payconiq {
	background: url('@/statics/png/payconiq/bg.png') no-repeat;
	background-size: cover;
}

ion-item {
	&::part(native) {
		padding-inline-start: 0 !important;
	}
}
</style>
