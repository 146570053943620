import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, unref as _unref, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { ref, watch } from 'vue';
import { IonModal } from '@ionic/vue';

// Props

export default /*@__PURE__*/_defineComponent({
  __name: 'ModalTwo',
  props: {
	isOpen: { type: Boolean, required: true, default: false },
},
  emits: ['didDismiss'],
  setup(__props, { emit: __emit }) {

const props = __props;

// Init
const emit = __emit;
const isOpenRef = ref(props.isOpen);

// Watch changes on the prop
watch(
	() => props.isOpen,
	(newValue) => {
		isOpenRef.value = newValue;
	},
);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonModal), {
    "is-open": isOpenRef.value,
    class: "h-full",
    onDidDismiss: _cache[0] || (_cache[0] = ($event: any) => (emit('didDismiss')))
  }, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _: 3
  }, 8, ["is-open"]))
}
}

})