import { Capacitor } from '@capacitor/core';

import { init } from '@sentry/capacitor';
import * as Sentry from '@sentry/capacitor';
import { UserProfile } from '@/shared/interfaces/user';
import { SentryScopeOptions } from '@/shared/interfaces/sentry';

export function bootstrapSentry() {
	if (process.env.VUE_APP_ENVIRONMENT !== 'local') {
		init({
			dsn: process.env.VUE_APP_SENTRY_DSN,
			environment: process.env.environment,
			release: Capacitor.isNativePlatform()
				? process.env.VUE_APP_SENTRY_NATIVE_RELEASE_KEY
				: process.env.VUE_APP_SENTRY_WEB_RELEASE_KEY,
			tracesSampleRate: 0,
			integrations: [],
		});
	}
}

export function updateScopes(options: SentryScopeOptions) {
	Sentry.withScope((scope) => {
		scope.setUser({
			id: options.userId ?? 'guest',
		});
		scope.setExtras({
			'tenant': options.tenantName,
			'userId': options.userId ?? 'guest',
			'lastScreenNavigatedTo': options.destination,
		});
	});
}

export function captureError(error: Error | unknown) {
	Sentry.captureException(error);
}

export function captureMessage(message: string) {
	Sentry.captureMessage(message);
}
