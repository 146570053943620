import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex flex-col justify-between px-[34px]" }
const _hoisted_2 = { class: "flex flex-col mt-12 mb-10 items-center" }
const _hoisted_3 = { class: "flex items-center justify-center mb-4" }
const _hoisted_4 = { class: "font-sourcesanspro-semibold text-lg2" }
const _hoisted_5 = {
  key: 0,
  class: "flex items-center justify-center mb-4"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = { class: "mb-8" }
const _hoisted_8 = { class: "flex items-center mb-4" }
const _hoisted_9 = { class: "font-sourcesanspro-semibold text-lg inline" }
const _hoisted_10 = {
  key: 0,
  class: "mb-4"
}
const _hoisted_11 = { class: "flex items-center mb-4" }
const _hoisted_12 = { class: "font-sourcesanspro-semibold text-lg inline" }
const _hoisted_13 = { class: "mb-8" }
const _hoisted_14 = { class: "flex w-full items-center justify-between" }
const _hoisted_15 = { class: "text-m2" }
const _hoisted_16 = { class: "text-m2" }

import { useRoute } from 'vue-router';
import { IonButton, IonContent, IonIcon, IonSpinner } from '@ionic/vue';
import { closeOutline, personAddOutline, ticketOutline } from 'ionicons/icons';
import { useI18n } from 'vue-i18n';

import { CartCategoriesEnum } from '@/enums/values.enum';
import { EventComboTicketType } from '@/shared/interfaces/eventComboTicketType';
import { EventTicketType } from '@/shared/interfaces/eventTicketType';
import { useCart } from '@/modules/shop/composables/useCart';
import ItemAdder from '@/shared/components/ui/ItemAdder.vue';
import useEvents from '@/modules/events/composables/useEvents';
import useToast from '@/shared/composables/useToast';

// Init

export default /*@__PURE__*/_defineComponent({
  __name: 'EventsTicketsBuyPage',
  setup(__props) {

const { t } = useI18n();
const route = useRoute();
const eventId = +route.params.id;
const { getEvent } = useEvents();
const { buyTicketLimits, decreaseQuantity, event, getEventTicketTypes, increaseQuantity } = getEvent(eventId);
const { eventComboTicketTypes, eventTicketTypes, isFetchedEventTicketTypes } = getEventTicketTypes(eventId);
const { getCartTotalItemsByCategory, getProductCountWithDuplicates } = useCart();
const { presentToast } = useToast();

// Handle decrease product
const onDecreaseProductQuantity = (item: EventTicketType | EventComboTicketType) => {
	decreaseQuantity(item);
};

// Handle increase product
const onIncreaseProductQuantity = async (productId: number, item: EventTicketType | EventComboTicketType) => {
	if (getCartTotalItemsByCategory(CartCategoriesEnum.ticket) + 1 > buyTicketLimits.value!.ticketLimit.find((ticketType) => ticketType.type === item.name)!.limit) {
		await presentToast('top', t('ticket.buy.event_limit', { eventName: event.value?.name }), 5000, 'danger');
	}
	if (getCartTotalItemsByCategory(CartCategoriesEnum.ticket) >= buyTicketLimits.value!.eventLimit) {
		await presentToast('top', t('ticket.buy.event_limit', { eventName: event.value?.name }), 5000, 'danger');
	}

	if (getCartTotalItemsByCategory(CartCategoriesEnum.ticket) < buyTicketLimits.value!.ticketLimit.find((ticketType) => ticketType.type === item.name)!.limit) {
		await increaseQuantity(productId, item);
	}
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(IonContent), null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_unref(IonButton), {
              class: "rounded ion-no-padding ion-no-margin w-[60px] h-[60px] hover:rounded-full hover:bg-white/20",
              routerLink: `/events/${eventId}`
            }, {
              default: _withCtx(() => [
                _createVNode(_unref(IonIcon), {
                  class: "text-[38px] text-white cursor-pointer",
                  icon: _unref(closeOutline)
                }, null, 8, ["icon"])
              ]),
              _: 1
            }, 8, ["routerLink"])
          ]),
          _createElementVNode("h1", _hoisted_4, _toDisplayString(_unref(event)?.name), 1)
        ]),
        (!_unref(isFetchedEventTicketTypes))
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createVNode(_unref(IonSpinner), { name: "dots" })
            ]))
          : _createCommentVNode("", true),
        (
					_unref(event) &&
					_unref(buyTicketLimits) &&
					_unref(isFetchedEventTicketTypes) &&
					_unref(eventTicketTypes) &&
					_unref(eventTicketTypes).ticket_types.length
				)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("div", _hoisted_8, [
                  _createVNode(_unref(IonIcon), {
                    class: "mr-2 text-[20px] text-white cursor-pointer",
                    icon: _unref(ticketOutline)
                  }, null, 8, ["icon"]),
                  _createElementVNode("h2", _hoisted_9, _toDisplayString(_unref(t)('ticket.buy.tickets')), 1)
                ]),
                _createElementVNode("div", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(eventTicketTypes).ticket_types, (eventTicketType, idx) => {
                    return (_openBlock(), _createBlock(ItemAdder, {
                      onOnDecreaseProductQuantity: 
								(product) => {
									onDecreaseProductQuantity(eventTicketType);
								}
							,
                      onOnIncreaseProductQuantity: 
								(product) => {
									onIncreaseProductQuantity(product.id, eventTicketType);
								}
							,
                      "add-more-error": "ticket.buy.ticket_type_limit",
                      id: eventTicketType.id,
                      "add-more-error-detail": eventTicketType.name,
                      description: eventTicketType.description,
                      expandable: eventTicketType.description ? true : false,
                      key: idx,
                      productId: +eventTicketType.id!,
                      product: eventTicketType,
                      class: "mb-4",
                      title: eventTicketType.name,
                      discount: eventTicketType.discount,
                      price: +eventTicketType.price,
                      priceWithDiscount: +eventTicketType.discountedPrice()!,
                      currency: eventTicketType.currency,
                      quantity: _unref(getProductCountWithDuplicates)(eventTicketType),
                      "can-add-more": 
								_unref(getCartTotalItemsByCategory)(_unref(CartCategoriesEnum).ticket) < _unref(buyTicketLimits).eventLimit &&
								(_unref(buyTicketLimits).ticketLimit.find(
									(ticketType) => ticketType.type === eventTicketType.name,
								)!.limit === -1
									? true
									: _unref(getProductCountWithDuplicates)(eventTicketType) + 1 <=
										_unref(buyTicketLimits).ticketLimit.find(
											(ticketType) => ticketType.type === eventTicketType.name,
										)!.limit)
							
                    }, null, 8, ["onOnDecreaseProductQuantity", "onOnIncreaseProductQuantity", "id", "add-more-error-detail", "description", "expandable", "productId", "product", "title", "discount", "price", "priceWithDiscount", "currency", "quantity", "can-add-more"]))
                  }), 128))
                ])
              ]),
              (_unref(isFetchedEventTicketTypes) && _unref(eventComboTicketTypes)?.length)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    _createElementVNode("div", _hoisted_11, [
                      _createVNode(_unref(IonIcon), {
                        class: "mr-2 text-[20px] text-white cursor-pointer",
                        icon: _unref(ticketOutline)
                      }, null, 8, ["icon"]),
                      _createElementVNode("h2", _hoisted_12, _toDisplayString(_unref(t)('ticket.buy.tickets_combination')), 1)
                    ]),
                    _createElementVNode("div", null, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(eventComboTicketTypes), (eventTicketCombo, idx) => {
                        return (_openBlock(), _createBlock(ItemAdder, {
                          onOnDecreaseProductQuantity: 
								(productId) => {
									onDecreaseProductQuantity(eventTicketCombo);
								}
							,
                          onOnIncreaseProductQuantity: 
								(product) => {
									onIncreaseProductQuantity(product.id, eventTicketCombo);
								}
							,
                          description: eventTicketCombo.description,
                          key: idx,
                          productId: +eventTicketCombo.id!,
                          product: eventTicketCombo,
                          class: "mb-4",
                          "can-add-more": true,
                          title: eventTicketCombo.name,
                          discount: eventTicketCombo.discount,
                          price: +eventTicketCombo.price,
                          priceWithDiscount: +eventTicketCombo.discountedPrice()!,
                          currency: eventTicketCombo.currency,
                          quantity: _unref(getProductCountWithDuplicates)(eventTicketCombo),
                          "sold-out": false
                        }, null, 8, ["onOnDecreaseProductQuantity", "onOnIncreaseProductQuantity", "description", "productId", "product", "title", "discount", "price", "priceWithDiscount", "currency", "quantity"]))
                      }), 128))
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_13, [
          (_unref(isFetchedEventTicketTypes))
            ? (_openBlock(), _createBlock(_unref(IonButton), {
                key: 0,
                disabled: _unref(getCartTotalItemsByCategory)(_unref(CartCategoriesEnum).ticket) === 0,
                class: "w-full hover:rounded-full hover:bg-white",
                expand: "full",
                size: "large",
                shape: "round",
                routerLink: `/events/${eventId}/tickets/addmembers`
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_14, [
                    _createElementVNode("span", _hoisted_15, _toDisplayString(_unref(t)('events.buy_more.add_members')), 1),
                    _createElementVNode("span", _hoisted_16, _toDisplayString(_unref(t)('events.buy_more.continue')), 1)
                  ])
                ]),
                _: 1
              }, 8, ["disabled", "routerLink"]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }))
}
}

})